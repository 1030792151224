/**
 *
 * @param file
 * @returns {Promise<*>}
 */
import {postDocumentFile, postDocumentImg, postImageNew, postTypeImg} from "../service/document_api";

export async function update(file) {
    let formData = new FormData()
    formData.append('file', file)
    return  await postDocumentImg(formData);
}
export async function update_File(file) {
    let formData = new FormData()
    formData.append('file', file)
    return  await postDocumentFile(formData);
}
//上传图片新 2025
export async function update_New(type,file) {
  let formData = new FormData();
  formData.append("file", file);
  return await postImageNew(type,formData);
}


//单个文件上传----不同类型上传到不同文件夹

export async function updateType(file,type) {
    let formData = new FormData()
    formData.append('file', file)
    return  await postTypeImg(formData,type);
}
