import { render, staticRenderFns } from "./PersonalCenterManagement.vue?vue&type=template&id=b7968164&scoped=true"
import script from "./PersonalCenterManagement.vue?vue&type=script&lang=js"
export * from "./PersonalCenterManagement.vue?vue&type=script&lang=js"
import style0 from "./PersonalCenterManagement.vue?vue&type=style&index=0&id=b7968164&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b7968164",
  null
  
)

export default component.exports