import {get,del,llget,drugPut,drugPost,drugDel,postUserId,putDragById,delUserId,drugGet,patch,getExel,post} from "./base"; //招聘管理页面

// 招聘信息删除
export function delInfo(id){
    return del(`/v3/educationRecruit/info/${id}`);
}
// 导出订阅记录
export function getSubscribeExport(data){
    return getExel("/v3/educationRecruitSubscribe/subscribe/export",data);
}
// 关闭开关状态 /v3/educationRecruitRecommend/recommend/content/{id}
export function delRecommend(id){
    return del(`/v3/educationRecruitRecommend/recommend/content/${id}`);
}
// 分享记录管理
export function getShareRecord(startTime, endTime, curPage, pageSize) {
    return drugGet(`/v3/educationRecruitShareRecords/shareRecord?startTime=${startTime}&endTime=${endTime}&curPage=${curPage}&pageSize=${pageSize}`);
}
// 培训内容--修改  
export function putTrain(data){
    return drugPut("/v3/educationRecruit/info",data);
}
// 培训内容--新增  
export function postTrain(data){
    return drugPost("/v3/educationRecruit/info",data);
}
// 培训内容--获取  
export function getTrain(type, title, curPage, pageSize, year, isPublish, isDeadLine) {
  return drugGet(
    `/v3/educationRecruit/info?type=${type}&title=${title}&curPage=${curPage}&pageSize=${pageSize}&year=${year}&isPublish=${isPublish}&isDeadLine=${isDeadLine}`
  );
}
// 招聘信息--获取  
export function getRecruit(
  type,
  title,
  curPage,
  pageSize,
  year,
  isPublish,
  isDeadLine
) {
  return drugGet(
    `/v3/educationRecruit/info?type=${type}&title=${title}&curPage=${curPage}&pageSize=${pageSize}&year=${year}&isPublish=${isPublish}&isDeadLine=${isDeadLine}`
  );
}
// 招聘信息--新增  
export function postRecruit(data){
    return drugPost("/v3/educationRecruit/info",data);
}
// 招聘信息--修改  
export function putRecruit(data){
    return drugPut("/v3/educationRecruit/info",data);
}
// 招聘信息--下拉框  
export function getSelect(code){
    return drugGet(`/v3/educationRecruitDictionary/content/${code}`);
}
// 搜索记录 
export function getSearchRecords(startTime, endTime, curPage, pageSize) {
    return drugGet(`/v3/educationRecruitSearchRecords/searchRecords?startTime=${startTime}&endTime=${endTime}&curPage=${curPage}&pageSize=${pageSize}`);
}
// 订阅记录
export function getSubscribe(startTime, endTime, curPage, pageSize) {
    return drugGet(`/v3/educationRecruitSubscribe/subscribe?startTime=${startTime}&endTime=${endTime}&curPage=${curPage}&pageSize=${pageSize}`);
}
// 关注记录
export function getFollow(startTime, endTime, curPage, pageSize) {
    return get(`/v3/educationRecruitFollow/follow?startTime=${startTime}&endTime=${endTime}&curPage=${curPage}&pageSize=${pageSize}`);
}
// 推荐开关  
export function postRecommend(data){
    return post("/v3/educationRecruitRecommend/recommend",data);
}
// 获取开关状态
export function getRecommend(id){
    return get(`/v3/educationRecruitRecommend/recommend/${id}`);
}
// 导出搜索收藏  
export function getSearchExport(data){
    return getExel("/v3/educationRecruitSearchRecords/searchRecords/export",data);
}
// 导出分享记录  /v3/educationRecruitShareRecords/shareRecord/export
export function getShareExport(data){
    return getExel("/v3/educationRecruitShareRecords/shareRecord/export",data);
}
// 导出关注记录  /v3/educationRecruitFollow/follow/export
export function getFollowExport(data){
    return getExel("/v3/educationRecruitFollow/follow/export",data);
}
// 小程序推荐  
export function postminaRecommend(data){
    return post("/v3/recommend/mina",data);
}
// 推荐到小程序  
export function getminaRecommend(data){
    return get("/v3/yun/promote/mina",data);
}
// 取消推荐小程序  /v3/yun/promote/mina
export function delminaRecommend(data){
    return del("/v3/recommend/mina",data);
}
// 职称考试首页是否推荐回显
export function getExanRecommend(contentId,contentType,examTypeId) {
  return get(`/v3/h5/exam/recommend/${contentId}/${contentType}/${examTypeId}`);
}
// 职称考试取消推荐
export function delExanRecommend(contentId,contentType,examTypeId) {
    return del(`/v3/h5/exam/recommend/${contentId}/${contentType}/${examTypeId}`);
  }
// 获取图片类型
export function getShowType() {
    return get(`/v3/upload/back/show/type`);
}
// 获取图片
export function getShowPicture(data) {
    return get(`/v3/upload/back/show/picture`,data);
}

