<template>
  <div style="margin: -15px -15px; background-color: #f0f2f5">
    <!-- tag查询框 -->
    <div class="datePicker">
      <div class="date">
        药品名称：
        <a-input
          style="width: 180px; margin-right: 20px"
          placeholder="请输入"
          v-model="searchName"
          @keyup.enter="searchBtn"
        ></a-input>
      </div>
      <!-- <div class="date">
         暂时屏蔽药品类型：
         <a-input style="width: 180px"  placeholder="请输入" v-model="searchName" @keyup.enter="searchBtn"></a-input>
       </div> -->
      <div style="display: flex; align-items: center; margin-right: 50px">
        <span>状态</span>: &nbsp;
        <a-radio-group v-model="state" @change="searchOk">
          <a-radio-button value="0"> 未发布 </a-radio-button>
          <a-radio-button value="1"> 已发布 </a-radio-button>
        </a-radio-group>
      </div>
      <a-button type="primary" style="margin-left: 30px" @click="searchBtn"
        >查询</a-button
      >
      <!-- @click="returnBtn" -->
      <a-button style="margin-left: 15px; margin-right: 40px" @click="returnBtn"
        >重置</a-button
      >
      <!-- 新建的表单 -->
      <a-drawer
        title="新建/修改"
        placement="right"
        :closable="false"
        :visible="addShow"
        @close="addCancel"
        width="40%"
      >
        <a-form-model
          :model="addForm"
          :label-col="labelCol"
          :rules="rules"
          :wrapper-col="wrapperCol"
          ref="add"
        >
          <a-form-model-item prop="drugName" label="药品名称">
            <a-textarea
              v-model="addForm.drugName"
              auto-size
              style="min-height: 100px; font-size: 16px"
            />
          </a-form-model-item>
          <a-form-model-item prop="drugOverview" label="简介">
            <!-- <a-textarea v-model="addForm.drugOverview" auto-size style="min-height: 100px;font-size: 16px"/> -->
            <div
              v-if="addShow == true"
              style="border: 1px solid #ccc; min-height: 301px; width: 450px"
            >
              <Toolbar
                style="border-bottom: 1px solid #ccc"
                :editor="editor"
                :defaultConfig="toolbarConfig"
                :mode="mode"
              />
              <Editor
                style="overflow-y: hidden"
                v-model="addForm.drugOverview"
                :defaultConfig="editorConfig"
                :mode="mode"
                @onCreated="onCreated"
              />
            </div>
          </a-form-model-item>
          <a-form-model-item prop="detail" label="明细">
            <a-table
              :columns="modelColumns"
              :data-source="addForm.detail"
              :pagination="false"
              rowKey="id"
              style="margin-top: 20px"
            >
              <template slot="value" slot-scope="text, row, index">
                <a-input
                  placeholder="请输入值"
                  v-model="row.value"
                  v-if="btnType == 'edit'"
                />
                <span v-if="btnType == 'save'">{{ row.value }}</span>
              </template>
              <template slot="titles" slot-scope="text, row, index">
                <a-input
                  placeholder="请输入名称"
                  v-model="row.title"
                  v-if="btnType == 'edit'"
                />
                <span v-if="btnType == 'save'">{{ row.title }}</span>
              </template>
              <template slot="operation" slot-scope="text, row, index">
                <a-popconfirm
                  cancel-text="否"
                  ok-text="是"
                  title="是否删除这条数据?"
                  @confirm="modelTableDelete(index)"
                >
                  <a v-if="btnType == 'edit'">删除</a>
                  <a v-if="btnType == 'save'" disabled="true">删除</a>
                </a-popconfirm>
              </template>
            </a-table>

            <a-button
              style="text-align: center; width: 100%; margin-top: 5px"
              type="dashed"
              @click="handleAdd"
              v-if="this.btnType == 'edit'"
            >
              + 添加
            </a-button>
            <a-button
              type="primary"
              style="margin-top: 5px; float: right; margin-bottom: 10px"
              @click="tableSave"
              v-if="this.btnType == 'edit'"
            >
              保存
            </a-button>
            <a-button
              type="primary"
              style="margin-top: 5px; margin-bottom: 10px; float: right"
              @click="tableEdit"
              v-if="this.btnType == 'save'"
            >
              编辑
            </a-button>
            <!-- <div style="width:100%;height: 100px;"></div> -->
          </a-form-model-item>
          <a-form-model-item prop="content" label="内容">
            <a-table
              :columns="model2Columns"
              :data-source="addForm.content"
              :pagination="false"
              rowKey="id"
              style="margin-top: 20px"
            >
              <template slot="titles" slot-scope="text, row, index">
                <a-input
                  v-if="btnContentType == 'edit'"
                  placeholder="请输入名称"
                  v-model="row.title"
                />
                <span v-if="btnContentType == 'save'">{{ row.title }}</span>
              </template>
              <template slot="value" slot-scope="text, row, index">
                <a
                  @click="settingModal(row, index)"
                  v-if="btnContentType == 'edit'"
                  >设置</a
                >
                <a
                  @click="settingModal(row, index)"
                  disabled="true"
                  v-if="btnContentType == 'save'"
                  >设置</a
                >
                <!-- <a-input v-if="btnContentType == 'edit'" placeholder="请输入值" v-model="row.value"/>
                  <span v-if="btnContentType == 'save'">{{ row.value }}</span> -->
              </template>
              <template slot="operation" slot-scope="text, row, index">
                <a-popconfirm
                  cancel-text="否"
                  ok-text="是"
                  title="是否删除这条数据?"
                  @confirm="model2TableDelete(index)"
                >
                  <a v-if="btnContentType == 'edit'">删除</a>
                  <a v-if="btnContentType == 'save'" disabled="true">删除</a>
                </a-popconfirm>
              </template>
            </a-table>
            <a-button
              v-if="this.btnContentType == 'edit'"
              style="text-align: center; width: 100%; margin-top: 5px"
              type="dashed"
              @click="handle2Add"
            >
              + 添加
            </a-button>
            <a-button
              type="primary"
              v-if="this.btnContentType == 'edit'"
              style="margin-top: 5px; float: right; margin-bottom: 10px"
              @click="table2Save"
            >
              保存
            </a-button>
            <a-button
              type="primary"
              v-if="this.btnContentType == 'save'"
              style="margin-top: 5px; margin-bottom: 10px; float: right"
              @click="table2Edit"
            >
              编辑
            </a-button>
            <!-- <div style="width:100%;height: 50px;"></div> -->
          </a-form-model-item>
        </a-form-model>
        <div
          :style="{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 1,
          }"
        >
          <a-button
            :style="{ marginRight: '8px' }"
            @click="
              () => {
                this.addShow = false;
                this.btnContentType = 'save';
                this.btnType = 'save';
                this.$refs.add.resetFields();
              }
            "
          >
            取消
          </a-button>
          <a-button
            :style="{ marginRight: '8px' }"
            v-if="buttonType == 'newBuild'"
            type="primary"
            @click="addOk()"
          >
            确定
          </a-button>
          <a-button
            :style="{ marginRight: '8px' }"
            v-if="buttonType == 'edit'"
            type="primary"
            @click="editOk()"
          >
            确定
          </a-button>
        </div>
      </a-drawer>
    </div>
    <div style="background-color: #fff; padding: 20px; padding-bottom: 80px">
      <a-button
        type="primary"
        style="margin-bottom: 20px; margin-right: 3%"
        @click="newlyBuilt"
      >
        <!-- <a-icon type="plus" /> -->
        新建
      </a-button>
      <a-table
        :columns="columns"
        style="min-height: 500px; background-color: #fff"
        :data-source="HeplTable"
        :rowKey="(record) => record.id"
        :pagination="false"
      >
        <span slot="operate" slot-scope="text, item, index">
          <a @click="editBtn(item.id, item)" style="margin-right: 10px">修改</a>
          <a-popconfirm
            title="确定删除?"
            ok-text="是"
            cancel-text="否"
            @confirm="delBtn(item.id)"
            @cancel="cancel"
          >
            <a style="margin-right: 10px">删除</a>
          </a-popconfirm>
        </span>
        <span slot="changeJia" slot-scope="text, record, item, id">
          <a-switch
            @change="onChange(record.id, record.isPublish === 1 ? 0 : 1)"
            :checked="record.isPublish !== 0"
            checked-children="是"
            un-checked-children="否"
            default-checked
          />
        </span>
        <span slot="addTime" slot-scope="text, record, item, id">
          <span v-if="record.updateTime !== null">{{ record.updateTime }}</span>
          <span v-if="record.updateTime == null">{{ record.createdTime }}</span>
        </span>
      </a-table>
      <!-- table框的分页 -->
      <div
        style="
          margin-top: 28px;
          display: flex;
          align-items: center;
          justify-content: space-between;
        "
      >
        <span
          style="font-size: medium; color: #929292; font-size: 14px; bottom: 3%"
        >
          共{{ pagination.total }}条记录 第 {{ pagination.pageNum }} /
          {{ Math.ceil(pagination.total / pagination.pageSize) }} 页
        </span>
        <a-pagination
          style="float: right"
          show-quick-jumper
          show-size-changer
          :page-size.sync="pagination.pageSize"
          :total="pagination.total"
          v-model="pagination.current"
          @change="pageChange"
          @showSizeChange="pageChange"
        />
      </div>
    </div>
    <!-- 设置的弹窗 -->
    <a-modal v-model="settingShow" :title="rowTitle" @cancel="handleCancel">
      <!-- 富文本框 -->
      <div
        v-if="addShow == true"
        style="border: 1px solid #ccc; min-height: 301px; width: 450px"
      >
        <Toolbar
          style="border-bottom: 1px solid #ccc"
          :editor="settingEditor"
          :defaultConfig="toolbarConfig"
          :mode="mode"
        />
        <Editor
          style="overflow-y: hidden"
          v-model="settingHtml"
          :defaultConfig="editorConfig"
          :mode="mode"
          @onCreated="onSettingCreated"
        />
      </div>
      <div
        :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button
          :style="{ marginRight: '8px' }"
          @click="
            () => {
              this.settingShow = false;
              this.settingHtml = '';
              // 清空弹窗数据
              //  this.reviseForm.content = ''
              //  this.reviseForm.name = ''
            }
          "
        >
          取消
        </a-button>
        <a-button
          :style="{ marginRight: '8px' }"
          type="primary"
          @click="settingSubmit"
        >
          确认
        </a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import moment from "moment";
import { update } from "@/utils/update";
import { getHelp, postHelp, delHelp, putHelp } from "@/service/Help";
import {
  getDragList,
  putUpdate,
  postDrugs,
  getDrugsById,
  updateDrugs,
  delDrugs,
} from "@/service/DrugQueryManagement";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import axios from "axios";
const editorConfig = {
  // JS 语法
  MENU_CONF: {},
};
const signature = "";
// 修改 uploadImage 菜单配置
editorConfig.MENU_CONF["uploadImage"] = {
  // 自定义上传
  async customUpload(file, insertFn) {
    let formData = new FormData();
    formData.append("file", file);
    // file 即选中的文件,自己实现上传，并得到图片 url alt href
    axios
      .post(
        "https://yun-new-admin-api.yunicu.com/api/admin/common/update",
        formData
      )
      .then((res) => {
        if (res.data.code === 0) {
          insertFn(res.data.data.url); // 最后插入图片
        }
      });
  },
};
// 修改 uploadVideo 菜单配置
editorConfig.MENU_CONF["uploadVideo"] = {
  // 自定义上传
  async customUpload(file, insertFn) {
    let mediaFile = file;
    //获取签名
    axios
      .get("https://yun-new-admin-api.yunicu.com/v3/getUgcUploadSign")
      .then((res) => {
        if (res.data.code === 0) {
          const getSignature = () => {
            return res.data.data.signature;
          };
          const tcVod = new TcVod({
            getSignature: getSignature, //上传签名的函数
          });
          const uploader = tcVod.upload({
            mediaFile: mediaFile, // 媒体文件（视频或音频或图片），类型为 File
          });
          uploader.done().then((res) => {
            insertFn(res.video.url); // 进行视频处理：转码和截图
          });
        }
      });
  },
};
export default {
  components: { Editor, Toolbar },
  data() {
    return {
      settingShow: false,
      mode: "default", // or 'simple'
      editorConfig,
      editor: null,
      settingEditor: null,
      settingHtml: "",
      toolbarConfig: {},
      //  html: '',
      //  ht4ml: '',
      state: "",
      pagination: {
        //存储大table的分页
        total: 0,
        current: 1,
        pageSize: 10,
        pageNum: 1,
      },
      state: "1",
      addShow: false,
      rules: {
        drugName: [{ required: true, message: "请输入", trigger: "blur" }],
        drugOverview: [{ required: true, message: "请输入", trigger: "blur" }],
        detail: [{ required: true, message: "请输入", trigger: "blur" }],
        content: [{ required: true, message: "请输入", trigger: "blur" }],
      },
      labelCol: { span: 4 },
      wrapperCol: { span: 18 },
      addForm: {
        drugOverview: "",
        drugName: "",
        detail: [],
        content: [],
      },
      //  modelData: [],
      //  model2Data: [],
      modelColumns: [
        {
          title: "名称",
          dataIndex: "title",
          width: "25%",
          align: "center",
          scopedSlots: { customRender: "titles" },
        },
        {
          title: "值",
          dataIndex: "value",
          width: "30%",
          align: "center",
          scopedSlots: { customRender: "value" },
        },
        {
          title: "操作",
          width: "10%",
          scopedSlots: { customRender: "operation" },
        },
      ],
      model2Columns: [
        {
          title: "名称",
          dataIndex: "title",
          width: "25%",
          align: "center",
          scopedSlots: { customRender: "titles" },
        },
        {
          title: "值",
          dataIndex: "value",
          width: "30%",
          align: "center",
          scopedSlots: { customRender: "value" },
        },
        {
          title: "操作",
          width: "10%",
          scopedSlots: { customRender: "operation" },
        },
      ],
      columns: [
        {
          title: "药品名称",
          dataIndex: "drugName",
          key: "drugName",
          //    align:'center',
          //    width:'20%',
        },
        {
          title: "是否发布",
          dataIndex: "state",
          key: "state",
          align: "center",
          scopedSlots: { customRender: "changeJia" },
        },
        {
          title: "更新时间",
          dataIndex: "createdTime",
          key: "createdTime",
          align: "center",
          scopedSlots: { customRender: "addTime" },
        },
        {
          title: "操作",
          width: "20%",
          scopedSlots: { customRender: "operate" },
        },
      ],
      HeplTable: [],
      searchName: "", //框的内容
      editId: "",
      buttonType: "",
      id: "",
      btnType: "save",
      btnContentType: "save",
      contentIndex: 0,
      rowTitle: "",
    };
  },
  created() {
    this.getTable();
  },
  methods: {
    // 设置的弹窗
    settingModal(row, index) {
      console.log("行", row);
      this.rowTitle = "设置--" + row.title;
      this.settingHtml = row.value;
      this.contentIndex = index;
      this.settingShow = true;
    },
    //设置弹窗提交
    settingSubmit() {
      console.log("数据", this.settingHtml);
      console.log("索引", this.contentIndex);
      // 把值放进去 就是1里面的value 被赋值咯
      this.addForm.content[this.contentIndex].value = this.settingHtml;
      this.settingShow = false;
      console.log("表单", this.addForm.content);
    },
    // 设置弹窗取消
    handleCancel() {
      this.settingShow = false;
      this.settingHtml = "";
    },
    tableSave() {
      this.btnType = "save";
      // console.log("阅读表单",this.addForm.detail)
      for (var i = 0; i < this.addForm.detail.length; i++) {
        // if(this.addForm.detail[i].title == '' && this.addForm.detail[i].value == ''){
        //   console.log("第"+[i]+"个为空")
        // }
        if (
          this.addForm.detail[i].title == "" &&
          this.addForm.detail[i].value !== ""
        ) {
          this.btnType = "edit";
          this.$message.success("明细列表中第" + [i + 1] + "条名称为空请完善");
        }
      }
      this.addForm.detail = this.addForm.detail.filter(
        (item) => item.title !== "" || item.value !== ""
      );

      // // 存进去arr再删除
      // for(var j = 0;j< arr.length;j++){
      //   this.addForm.detail.splice(j, 1)
      //   console.log("？？？",arr[j])  //索引号没对上啊
      //   // this.addForm.detail.splice(index, 1)
      // }
    },

    //      // 删除词条
    //  deleteWords(wordIds){
    //      this.dictOrigin = this.dictOrigin.filter(item => !wordIds.includes(item.id))
    //  }

    table2Save() {
      this.btnContentType = "save";
      // console.log("表单",this.addForm.content)
      for (var i = 0; i < this.addForm.content.length; i++) {
        if (this.addForm.content[i].value == "<p><br></p>") {
          this.addForm.content[i].value == "";
        }
        if (
          this.addForm.content[i].title == "" &&
          this.addForm.content[i].value !== ""
        ) {
          this.btnContentType = "edit";
          this.$message.success("内容列表中第" + [i + 1] + "条名称为空请完善");
        }
        // if(this.addForm.content[i].title == '' && this.addForm.content[i].value !== '' || this.addForm.content[i].title == '' && this.addForm.content[i].value !== "<p><br></p>"){
        //   this.btnContentType = "edit"
        //   this.$message.success("内容列表中第"+[i+1]+"条名称为空请完善")
        // }
      }
      this.addForm.content = this.addForm.content.filter(
        (item) => item.title !== "" || item.value !== ""
      );
      // // 存进去arr再删除
      // for(var j = 0;j< arr.length;j++){
      //   this.addForm.content.splice(arr[j], 1)
      //   // console.log("是不是全打印了",arr[j])
      // }
    },
    tableEdit() {
      this.btnType = "edit";
    },
    table2Edit() {
      this.btnContentType = "edit";
    },
    handleAdd() {
      // 新增/编辑窗口 table 添加行数据
      this.btnType = "edit";
      let maxCount = 0;
      this.addForm.detail.forEach((item) => {
        if (item.id > maxCount) maxCount = item.id;
      });
      maxCount += 1;
      this.addForm.detail.push({
        id: maxCount,
        title: "",
        value: "",
      });
    },
    handle2Add() {
      // 新增/编辑窗口 table 添加行数据
      this.btnContentType = "edit";
      let maxCount = 0;
      this.addForm.content.forEach((item) => {
        if (item.id > maxCount) maxCount = item.id;
      });
      maxCount += 1;
      this.addForm.content.push({
        id: maxCount,
        title: "",
        value: "",
      });
    },
    // 删除
    modelTableDelete(index) {
      this.addForm.detail.splice(index, 1);
    },
    model2TableDelete(index) {
      this.addForm.content.splice(index, 1);
    },
    onCreated(editor) {
      this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
    },
    onSettingCreated(settingEditor) {
      this.settingEditor = Object.seal(settingEditor); // 一定要用 Object.seal() ，否则会报错
    },
    beforeDestroy() {
      const editor = this.editor;
      const settingEditor = this.settingEditor;
      if (editor == null) return;
      if (settingEditor == null) return;
      editor.destroy(); // 组件销毁时，及时销毁编辑器
      settingEditor.destroy(); // 组件销毁时，及时销毁编辑器
    },
    // 新建表单
    newlyBuilt() {
      this.addShow = true;
      this.addForm.drugOverview = "";
      this.addForm.drugName = "";
      // this.getIndex(this.index)
      // this.getVideoIndex(this.VideoIndex)
      // console.log("看看新建的form", this.form)
      this.buttonType = "newBuild";
      // this.startTime = (moment().format('YYYY-MM-DD'))
    },
    // 新建
    async addOk() {
      this.onCreated();
      for (var i = 0; i < this.addForm.detail.length; i++) {
        delete this.addForm.detail[i].id;
      }
      for (var i = 0; i < this.addForm.content.length; i++) {
        delete this.addForm.content[i].id;
      }
      this.addForm.detail = this.addForm.detail;
      this.addForm.content = this.addForm.content;
      //  console.log("表单",this.addForm)
      this.$refs.add.validate(async (valid) => {
        // 表单校验
        if (valid && this.btnContentType == "save" && this.btnType == "save") {
          const response = await postDrugs(this.addForm);
          if (response.code === 0) {
            // 刷新首页数据
            this.getTable();
            this.$message.success("新增成功");
            this.addShow = false;
            //  this.html = '';
            //  this.ht4ml = '';
            //  this.addForm.drugOverview = ''
            //  this.addForm.genericName = ''
            //  this.editor.destroy()
            //  this.editor = null
          } else {
            this.$message.error(response.message);
          }
          this.$refs.add.resetFields();
        } else {
          this.$message.error("请填写完整并保存");
        }
      });
    },
    editOk() {
      this.addForm.id = this.id;
      //  this.addForm.drugOverview = this.html;
      //  this.addForm.drugName = this.ht4ml;
      this.onCreated();
      if (this.addForm.drugOverview == "<p><br></p>") {
        this.addForm.drugOverview = "";
      }
      //  删id
      for (var i = 0; i < this.addForm.detail.length; i++) {
        delete this.addForm.detail[i].id;
      }
      for (var i = 0; i < this.addForm.content.length; i++) {
        delete this.addForm.content[i].id;
      }
      this.addForm.detail = this.addForm.detail;
      this.addForm.content = this.addForm.content;
      console.log("修改表单", this.addForm);
      this.$refs.add.validate(async (valid) => {
        // 表单校验
        if (valid && this.btnContentType == "save" && this.btnType == "save") {
          const Info = await updateDrugs(this.addForm);
          if (Info.code === 0) {
            this.getTable();
            //  this.html = '';
            //  this.ht4ml = '';
            //  this.addForm.drugOverview = ''
            //  this.addForm.genericName = ''
            //  this.editor.destroy()
            //  this.editor = null
            this.$message.success("修改成功");
          } else {
            this.$message.error("操作失败" + Info.message);
          }
          this.addShow = false;
          this.$refs.add.resetFields();
        } else {
          this.$message.error("请填写完整并保存");
        }
      });
    },
    // 弹窗取消
    addCancel() {
      this.$refs.add.resetFields();
      this.addShow = false;
      this.btnContentType = "save";
      this.btnType = "save";
    },
    // table列表
    async getTable() {
      const response = await getDragList(
        this.state,
        this.searchName,
        this.pagination.pageNum,
        this.pagination.pageSize
      );
      if (response.code === 0) {
        this.HeplTable = response.data;
        this.pagination.total = response.count;
        console.log("表", this.HeplTable);
      } else {
        this.$message.warning(response.message);
      }
    },
    // 状态框查询
    searchOk(e) {
      switch (e.target.value) {
        // 已发布
        case "1":
          this.state = 1;
          this.pagination.pageSize = 10;
          this.pagination.pageNum = 1;
          this.pagination.current = 1;
          this.getTable();
          break;
        // 未发布
        case "0":
          this.state = 0;
          this.pageSize = 10;
          this.pageNum = 1;
          this.current = 1;
          this.getTable();
          break;
      }
    },
    // 搜索框查询
    searchBtn() {
      // 重置页码
      this.pagination.pageNum = 1;
      this.pagination.pageSize = 10;
      this.pagination.current = 1;
      this.getTable();
    },
    // 分页查询
    pageChange(page_no, page_size) {
      this.pagination.pageNum = page_no;
      this.pagination.pageSize = page_size;
      this.getTable();
    },
    // 重置
    returnBtn() {
      this.searchName = ""; //输入框
      this.pagination.current = 1; //默认显示
      this.pagination.pageSize = 10; //行数
      this.pagination.pageNum = 1;
      this.state = ""; //状态
      this.state = "";
      this.getTable();
    },
    // 删除
    async delBtn(id) {
      const response = await delDrugs(id);
      if (response.code == 0) {
        this.getTable();
        this.$message.success("删除成功");
      } else {
        this.$message.warning(response.message);
      }
    },

    async onChange(id, open) {
      const data = {
        id: id,
        isPublish: open,
      };
      const Info = await putUpdate(data);
      if (Info.code === 0) {
        this.getTable();
        this.$message.success("操作成功");
      } else {
        this.$message.error("操作失败" + Info.message);
      }
    },
    // 点击修改
    async editBtn(id, item) {
      //   this.$set(this.editForm, 'title', item.catalogue)
      this.addShow = true; //注意渲染先后顺序，要先打开表单再执行接口
      this.id = id;
      this.onCreated();
      this.buttonType = "edit";
      const Info = await getDrugsById(id);
      if (Info.code === 0) {
        // console.log("单条数据",Info)
        this.addForm = JSON.parse(JSON.stringify(Info.data));
        // 需要添加id放到两个表里面
        // detail表
        var data = [];
        data = Info.data.detail;
        for (var i = 0; i < Info.data.detail.length; i++) {
          let key = "id";
          data[i][key] = i;
        }
        this.addForm.detail = data;

        // content表
        var contentData = [];
        contentData = Info.data.content;
        for (var i = 0; i < Info.data.content.length; i++) {
          let key = "id";
          contentData[i][key] = i;
        }
        this.addForm.content = contentData;
        // setTimeout(() => { 可能不需要了
        //   // this.html = Info.data.drugOverview;
        //   // this.ht4ml = Info.data.drugName;
        // }, 100)
      } else {
        this.$message.error(Info.message);
      }
    },
    // 取消
    cancel() {},
  },
};
</script>

<style lang="scss" scoped>
.datePicker {
  background-color: #fff;
  width: 100%;
  height: 64px;
  padding: 20px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  position: relative;

  .status {
    float: right;
    position: absolute;
    right: 50px;
  }
}

::v-deep .ant-table-thead > tr > th {
  font-weight: bold;
}
</style>
