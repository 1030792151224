<template>
  <div style="z-index: 99999;">
    <!-- :footer="null" -->
    <a-modal
      title="选择照片"
      :visible="visible"
      width="80%"
      :footer="null"
      @cancel="Cancel"
    >
    <!-- 菜单 :style="{minHeight: screeHeight+'px'}" :style="{minHeight: screeHeight+'px'}"-->
    <div class="UseDailyPracticeRecord">
      <div class="Previous_left">


        <!-- <a-menu
              v-model="menu"
              mode="inline"
              style="width: 100%;"
          >
            <a-menu-item
                v-for="item in typeInfo"
                :key="item.code"
                @click="leftChange(item.code)"
            >
              {{ item.title }}
            </a-menu-item>
          </a-menu> -->

        <a-menu
          @select="selectChange"
          :selectedKeys="selectedKeys"
          mode="inline" 
        >
          <a-menu-item v-for="item in menuList" :key="item.type">
            <div style="display:flex;justify-content: space-between">
               <span>{{ item.title }}</span>              
            </div>
          </a-menu-item>
        </a-menu>
      </div>
      <div class="Previous_right">
        <div class="log">
          <a-spin :spinning="spinning">
            <div>
              <div class="search">
                标题：        
                <a-input-search
                  placeholder="请输入"
                  enter-button="搜索"
                  v-model="inputValue"
                  @search="onSearch"
                  style="width: 15vw"
                  allowClear
                />
              </div>
            </div>
            <div class="table">
              <a-table
                  :columns="columns"
                  :data-source="tableData"
                  :pagination="false"
                  :rowKey="record=>record.id"
                  >
                <!-- <span slot="title" slot-scope="text,record" >
                  <a-tooltip placement="topLeft">
                    <template slot="title">
                      {{ text }}
                    </template>
                    <div class="multi-ellipsis"> <span>{{ record.title }}</span></div>
                  </a-tooltip>
                </span> -->
                <span slot="url" slot-scope="text, record">
                  <img :src="text" alt="" style="width:300px;height: 150px;">
                </span>
                <span slot="operation" slot-scope="text, record">
                 <a-popconfirm
                    title="确认选择?"
                    ok-text="是"
                    cancel-text="否"
                    @confirm="choice(record)"
                  >
                  <a >选  择</a>
                  </a-popconfirm>
                </span>
              </a-table>
              <!--分页-->
              <div class="pageView">
                <span class="pageLeft">共 {{ pagination.total }} 条记录 第 {{
                  pagination.current
                }} / {{ Math.ceil(pagination.total / pagination.pageSize) }} 页</span>
                <a-pagination v-model="pagination.current"
                  :page-size.sync="pagination.pageSize"
                  :total="pagination.total"
                  show-quick-jumper show-size-changer
                  @change="pageChange"
                  @showSizeChange="pageChange"/>
              </div>
            </div>
          </a-spin>
        </div>
      </div>
    </div>
    </a-modal>
  </div>

</template>

<script>
import {getShowType,getShowPicture} from "@/service/Recruiting";
export default {
  props: {
    // 接收父组件传递的参数
    visible: {
      type: Boolean,
      default: false,
    },
    // projectId: {
    //   type: String,
    // },
    // renyuan: {
    //   type: String,
    // //   default: false,
    // },
    // itemIspending: {
    //   type: Number,
    // //   default: false,
    // },
  },
  data(){
    return{
      pagination: {
        total: 0,
        pageSize: 10,
        current: 1,
      },
      spinning:false,
      selectedKeys: [''],
      menuList:[],
      inputValue:'',
      columns: [
        {
          title: '标题',
          align: 'center',
          dataIndex: 'title',
          width:'20%',
          scopedSlots: {customRender: "title"},
        },
        {
          title: '图片',
          align: 'center',
          dataIndex: 'url',
          scopedSlots: {customRender: "url"},
        },
        {
          title: '操作',
          align: 'center',
          width:200,
          scopedSlots: {customRender: "operation"},
        },
      ],
      tableData:[],
    }
  },
  created(){
    this.getMenuType()
  },
  methods:{
    choice(record){
      // this.$emit("choicePIC", record); //子组件向父组件传值（开关）
      // console.log(record)
      this.pagination.current = 1
      this.pagination.pageSize = 10
      this.inputValue = ''
      this.selectedKeys[0] = this.menuList[0].type
      this.getMenuPicture()
      this.$emit("closePIC", record.url); //子组件向父组件传值（开关）
    },
    onSearch() {
      this.pagination.current = 1
      this.pagination.pageSize = 10
      this.getMenuPicture()
      // if (this.tabValue === '1') {
      //   // 我的上传
      //   this.getMyCaseTable()
      // } else if (this.tabValue  === '2') {
      //   // 全部病例
      //   this.getAllCaseTable()
      // }
    },
    // 获取图片类型
    async getMenuType(){
      const response = await getShowType()
        if(response.code === 0){
          // this.$message.success("操作成功！")
          this.menuList = response.data
          this.selectedKeys[0] = this.menuList[0].type
          this.getMenuPicture()
                    // console.log("菜单",response)
        }else {
          this.$message.warning(response.message)
        }
    },
    // 获取图片
    async getMenuPicture(){
      this.spinning = true
      let data = {
        type:this.selectedKeys[0],
        pageNo:this.pagination.current ,
        pageSize:this.pagination.pageSize,
        title:this.inputValue
      }
      const response = await getShowPicture(data)
      if(response.code === 0){
        // console.log("图片",response)
        this.tableData = response.data
        this.pagination.total = response.count
      }else {
        this.$message.warning(response.message)
      }
        this.spinning = false
    },
    pageChange(current, pageSize) {
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getMenuPicture() 
    },
    Cancel() {
      let flag = false;
      this.pagination.current = 1
      this.pagination.pageSize = 10
      this.inputValue = ''
      this.selectedKeys[0] = this.menuList[0].type
      this.getMenuPicture()
      this.$emit("closePIC"); //子组件向父组件传值（开关）
    },
    modalOk() {
      let flag = false;
      this.$emit("closePIC"); //子组件向父组件传值（开关）
    },
    // 菜单
    selectChange (e) {
      this.selectedKeys = e.selectedKeys
      this.pagination.current = 1
      this.pagination.pageSize = 10
      this.inputValue = ''
      this.getMenuPicture()
    },
  },
}
</script>

<style scoped lang="scss">
.UseDailyPracticeRecord {
//   margin: -15px -15px;
  display: flex;
  justify-content: space-between;
  .Previous_left{
    // width: 10%;
    flex: 1;
  }
  .Previous_right{
    // border-left: 15px solid #F0F2F5;
    width: 85%;
  }
}
::v-deep .ant-modal-body{
    padding:0
  }
  .search {
    // background-color: white;
    // height: 70px;
    display: flex;
    align-items: center;
    margin-top: 15px;
    margin-left: 15px;
  }
  .table {
    background-color: #fff;
    padding: 20px;
  }
  .multi-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; // 超出一行则以...形式隐藏
  -webkit-box-orient: vertical;
  cursor: pointer;
  // background-color: green;
}
.pageView {
      display: flex;
      justify-content: space-between;
      z-index: 3;
      margin-top: 40px;
      margin-bottom: 40px;
    }
</style>