<template>
  <div class="homepageTemplate">
    <div class="Info_search">
      模版 &nbsp;<a-select v-model="topSelectVal" placeholder="选择模板搜索"  style="width: 180px;margin-right: 15px" @change="selectName" >
      <a-select-option v-for="item in templateTitle " :key="item.id" :value="item.name">
        {{item.name}}
      </a-select-option>
    </a-select>
      <a-button @click="selectReset">重置</a-button>
    </div>
    <div class="Info_list">
      <div class="addBtn"  @click="addBtn(0)">
        +新增
      </div>
      <div class="Info_list_Template" v-for="(item,index) in templates" :key="item.id">
        <div class="Template">
          <span class="Template_title"></span>
          <span class="Template_title_top">{{ item.quantity+1 }}个模板</span>
          <div class="Template_pic">
            <img style="width: 80%;height: 231px" :src="item.egImg" alt="">
          </div>
        </div>
        <a-divider style="margin: 5px 0 0 0 ;height: 1px; padding: 0" />
        <div class="Template_btn">
          <div class="btn" @click="addsBtn(item.id)">新增</div>
          <a-divider type="vertical" />
          <div class="btn" @click="downFile(item.id)">下载</div>
          <a-divider type="vertical" />
<!--          <div class="btn" @click="templateBtn(item.id)">选择</div>-->
          <div class="btn" @click="selectBtn(item.explains)">说明</div>
<!--          <a-divider type="vertical" />-->
<!--          <div class="btn" @click="editBtn(item.id)">编辑</div>-->
<!--          <a-divider type="vertical" />-->
<!--          <a-popconfirm-->
<!--              title="确定删除?"-->
<!--              ok-text="是"-->
<!--              cancel-text="否"-->
<!--              @confirm="delTemplates(item.id)"-->
<!--              @cancel="cancel"-->
<!--          >-->
<!--            <div class="btn">删除</div>-->
<!--          </a-popconfirm>-->

        </div>
      </div>
    </div>
    <div style="width: 100%;height: 50px;float: left;">
      <div style="float: right;margin-right: 20px;margin-top: 15px">
        <a-pagination v-model="pagination.current"
                      :total="pagination.total"
                      @change="pageChange" />
      </div></div>
    <!-------------------------  新增弹框--------------------------->
    <a-drawer
        title="新增"
        width="700"
        :visible="addShow"
        @close="closeAdd"
    >
      <a-form-model  :rules="rules"  :model="addForm" :label-col="labelCol" :wrapper-col="wrapperCol" ref="addItem" >
        <a-form-model-item style="margin-bottom: -10px;" label="示例图" prop="egImg">
          <div style="display: flex;flex-wrap: wrap;justify-content: space-between;width: 60%;margin-bottom: -10px">
            <div class="uploadImg">
              <input style="cursor:pointer;width: 100px;height: 100px;opacity: 0;" accept="image/*" type="file" id="add_egImg" @change="uploadPic('add_egImg')" ref="add_egImg" />
            </div>
            <div class="upload_image">
              <img
                  alt=""
                  v-if="addForm.egImg"
                  style="width: 95px;height:95px;"
                  :src="addForm.egImg"
              />
            </div>
          </div>
          <span style="font-size: 10px">注：尺寸 宽640，大小不超过1M</span>
        </a-form-model-item>
        <a-form-model-item label="模板" prop="tempId">
          <a-select placeholder="选择模板" prop="tempId" v-model="addForm.tempId">
            <a-select-option
                v-for="item in templateTitle"
                :value="item.id"
                @click="typeSelect(item.id,'add')">{{item.name}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item style="margin-bottom: -10px" label="背景图" >
          <div style="display: flex;flex-wrap: wrap;justify-content: space-between;width: 60%;margin-bottom: -10px">
            <div class="uploadImg">
              <input style="cursor:pointer;width: 100px;height: 100px;opacity: 0;" accept="image/*" type="file" id="bgpic" @change="uploadPic('bgpic')" ref="bgpic" />
            </div>
            <div class="upload_image">
              <img
                  alt=""
                  v-if="addForm.bgImg"
                  style="width: 95px;height:95px;"
                  :src="addForm.bgImg"
              />
            </div>
          </div>
          <span style="font-size: 10px">注：尺寸 宽640，大小不超过1M</span>
        </a-form-model-item>
        <a-form-model-item label="主色值" >
          <a-input v-model="addForm.mainColor">
            <input :value="addForm.mainColor" type="color" slot="addonBefore" @change="colorAdd" style="outline-style: none ;border: 0; padding: 0;height: 29px">
          </a-input>
        </a-form-model-item>
        <a-form-model-item label="源文件" prop="psdFile">
          <div class="upload_File">
            <input :disabled="loadImg" style=" cursor:pointer;width:150px;height: 100%;opacity: 0;"  type="file" accept=".psd" ref="addFile" id="addFile" @change=" uploadFile('addFile')"  />
          </div>
          <span style="font-size: 10px">支持扩展名：.psd</span>
          <div style="color: red" v-if="loadImg">
            上传中...
          </div>
          <div style="font-size: 8px;width: 600px">{{addForm.psdFile}}</div>
        </a-form-model-item>
        <a-form-model-item label="说明" >
          <a-textarea
              v-model="addForm.explains"
              style="margin-bottom: 20px"
              placeholder="请输入"
              :auto-size="{ minRows: 10, maxRows: 15 }"
          />
        </a-form-model-item>
<!--        <a-form-model-item label="常用功能图标" prop="func_cu">-->
<!--        </a-form-model-item>-->
<!--        <a-form-model-item >-->
<!--          <div style="display: flex;flex-wrap: wrap;width: 130%;font-size: 12px">-->
<!--            <div class="PIC_list" v-for="(list,index) in templateIcon" :key="index">-->

<!--              <div class="addIcon" :style="{backgroundImage: list.icon ? ' url(' + list.icon + ') ' :''}">-->
<!--                <input :id="'add' + index" style="cursor:pointer;width: 50px;height: 50px;opacity: 0;" accept="image/*" type="file"-->
<!--                       @change="addIcon(index)" ref="pic" />-->
<!--              </div>-->
<!--              <div style="display: flex;justify-content: center;">-->
<!--                <span>{{list.name}}</span>-->
<!--              </div>-->

<!--            </div>-->
<!--          </div>-->
<!--        </a-form-model-item>-->
      </a-form-model>

      <div
          :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'right',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
        }"
      >
        <a-button style="marginRight: 8px" @click="closeAdd">
          取消
        </a-button>
        <a-button type="primary" @click="addOk">
          确认
        </a-button>
      </div>
    </a-drawer>

    <!------------修改弹窗-------------->
    <a-drawer
        title="修改"
        width="700"
        :visible="editShow"
        @close="closeEdit"
    >
      <a-form-model :rules="rules"  :model="editForm" :label-col="labelCol" :wrapper-col="wrapperCol" ref="editItem" >
        <a-form-model-item style="margin-bottom: -10px;" label="示例图" prop="egImg">
          <div style="display: flex;flex-wrap: wrap;justify-content: space-between;width: 60%;margin-bottom: -10px">
            <div class="uploadImg">
              <input style="cursor:pointer;width: 100px;height: 100px;opacity: 0;" accept="image/*" type="file" id="edit_egImg" @change="uploadPic('edit_egImg')" ref="edit_egImg" />
            </div>
            <div class="upload_image">
              <img
                  alt=""
                  v-if="editForm.egImg"
                  style="width: 95px;height:95px;"
                  :src="editForm.egImg"
              />
            </div>
          </div>
          <span style="font-size: 10px">注：尺寸 宽640，大小不超过1M</span>
        </a-form-model-item>
        <a-form-model-item label="模板" prop="tempId">
          <a-select placeholder="选择模板" v-model="editForm.tempId" >
            <a-select-option v-for="item in templateTitle"   :value="item.id"  @click="typeSelect(item.id,'edit')">{{item.name}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item style="margin-bottom: -10px" label="背景图" >
          <div style="display: flex;flex-wrap: wrap;justify-content: space-between;width: 60%;margin-bottom: -10px">
            <div class="uploadImg">
              <input style="cursor:pointer;width: 100px;height: 100px;opacity: 0;"
                     accept="image/*"
                     type="file"
                     id="edit_bgpic"
                     @change="uploadPic('edit_bgpic')"
                     ref="edit_bgpic" />
            </div>
            <div class="upload_image">
              <img
                  alt=""
                  v-if="editForm.bgImg"
                  style="width: 95px;height:95px;"
                  :src="editForm.bgImg"
              />
            </div>
          </div>
          <span style="font-size: 10px">注：尺寸 宽640，大小不超过1M</span>
        </a-form-model-item>
        <a-form-model-item label="主色值">
          <a-input v-model="editForm.mainColor">
            <input :value="editForm.mainColor" type="color" slot="addonBefore" @change="colorEdit" style="outline-style: none ;border: 0; padding: 0;height: 29px">
          </a-input>
        </a-form-model-item>
        <a-form-model-item label="源文件" prop="psdFile">
          <div class="upload_File">
            <input :disabled="loadImg"  style=" cursor:pointer;width:150px;height: 100%;opacity: 0;"  type="file"  ref="editFile" id="editFile" @change=" uploadFile('editFile')"  />
          </div>
          <span style="font-size: 10px">支持扩展名：.psd</span>
          <div style="color: red" v-if="loadImg">
            上传中...
          </div>
          <div style="font-size: 8px;width: 600px">{{editForm.psdFile}}</div>
        </a-form-model-item>
        <a-form-model-item label="说明" >
          <a-textarea
              style="margin-bottom: 20px"
              v-model="editForm.explains"
              placeholder="请输入"
              :auto-size="{ minRows: 10, maxRows: 15 }"
          />
        </a-form-model-item>
<!--        <a-form-model-item label="常用功能图标" prop="func_cu">-->
<!--        </a-form-model-item>-->
<!--        <a-form-model-item >-->
<!--          <div style="display: flex;flex-wrap: wrap;width: 130%;font-size: 12px">-->
<!--            <div class="PIC_list" v-for="(list,index) in templateEdit" :key="index">-->

<!--              <div class="addIcon"  :style="{backgroundImage: list.icon ? 'url(' + list.icon + ')': ' '}">-->
<!--                <input :id="'edit' + index" style="cursor:pointer;width: 50px;height: 50px;opacity: 0;" accept="image/*" type="file"-->
<!--                       @change="editIcon(index)" ref="editPic" />-->
<!--              </div>-->
<!--              <div style="display: flex;justify-content: center;">-->
<!--                <span>{{list.name}}</span>-->
<!--              </div>-->

<!--            </div>-->
<!--          </div>-->
<!--        </a-form-model-item>-->
      </a-form-model>

      <div
          :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'right',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
        }"
      >
        <a-button style="marginRight: 8px" @click="closeEdit">
          取消
        </a-button>
        <a-button type="primary" @click="editOk">
          确认
        </a-button>
      </div>
    </a-drawer>

    <a-modal :footer="null" v-model="explainsVisible" title="说明" @ok="explainsOk" @cancel="explainsCancel">
<!--      <p>{{explainsValue}}</p>-->
<!--      <p v-html="explainsValue"></p>-->
      <p v-html="explainsValue.replace(/\n|\r\n/g, '<br>').replace(/ /g, ' &nbsp')"></p>
    </a-modal>
    <!-------------------------选择模板--------------->
    <!-------------------------选择会议--------------->
    <a-modal width="75%" :dialogStyle="{ 'top': '5%'}" v-model="templateShow" title="选择模板" :footer="null" :maskClosable="false" @cancel="close">
      <a-steps :current="current">
        <a-step v-for="item in steps" :key="item.title" :title="item.title" />
      </a-steps>
      <div class="steps-content" v-show="show1">
        <div style="display: flex">
          <div class="select_Info" style="width: 50%" >
            <a-form-model  :model="selectForm" :label-col="labelCol1" :wrapper-col="wrapperCol1" :rules="selectRef" ref="selectRef1">
              <a-form-model-item  label="封面图" prop="cover">
                <div style="display: flex;flex-wrap: wrap;justify-content: space-between;width: 70%;margin-bottom: -10px">
                  <div class="uploadImg">
                    <input style="cursor:pointer;width: 100px;height: 100px;opacity: 0;" accept="image/*" type="file" @change="uploadPic2('cover')" ref="cover" />
                  </div>
                  <div class="upload_image">
                    <img
                        alt=""
                        v-if="selectForm.cover"
                        style="width: 95px;height:95px;"
                        :src="selectForm.cover"
                    />
                  </div>
                </div>
                <span style="font-size: 10px;margin-left: -90px">注：尺寸 640*360，大小不超过2M</span>
              </a-form-model-item>
              <a-form-model-item label="主色值">
                <a-input v-model="selectForm.mainColor" placeholder="请选择主色值">
                  <input  :value="selectForm.mainColor" type="color" slot="addonBefore" @change="colorSelect" style="outline-style: none ;border: 0; padding: 0;height: 29px">
                </a-input>
              </a-form-model-item>

              <a-form-model-item  label="背景图" style="margin-top: 10px" >
                <div style="display: flex;flex-wrap: wrap;justify-content: space-between;width:70%;margin-bottom: -10px">
                  <div class="uploadImg">
                    <input style="cursor:pointer;width: 100px;height: 100px;opacity: 0;" accept="image/*" type="file" id="bghomePic" @change="uploadPic('bghomePic')" ref="bghomePic" />
                  </div>
                  <div class="upload_image">
                    <img
                        alt=""
                        v-if="selectForm.bgImg"
                        style="width: 95px;height:95px;"
                        :src="selectForm.bgImg"
                    />
                  </div>
                </div>
                <span style="font-size: 10px;margin-left: -90px">注：尺寸 宽640，大小不超过1M</span>
              </a-form-model-item>


            </a-form-model>

          </div>

          <div class="select_Info" style="width: 50%" >
            <a-form-model  :model="selectForm" :label-col="labelCol1"  :rules="selectRef" ref="selectRef"  :wrapper-col="wrapperCol1" >
              <a-form-model-item label="模板">
                <a-input disabled v-model="selectForm.Info" />
              </a-form-model-item>
              <a-form-model-item prop="title" label="会议名称">
                <a-input v-model="selectForm.title" placeholder="请填写会议名称"/>
              </a-form-model-item>
              <a-form-model-item prop="address" label="会议地址">
                <a-input v-model="selectForm.address" placeholder="请填写会议地址" />
              </a-form-model-item>

              <a-form-model-item prop="selectTimes" label="会议日期" >
                <a-range-picker v-model="selectForm.selectTimes"  />
              </a-form-model-item>
              <a-form-model-item   label="所属平台" prop="domain_code">
                <a-select placeholder="选择模板" v-model="selectForm.domainCode" >
                  <a-select-option v-for="list in option"  :value="list.code" @click="selectMode(list.value)">
                    {{list.name}}
                  </a-select-option>

                </a-select>
              </a-form-model-item>
              <a-form-model-item  prop="sort_time" label="直播开始时间">
                <a-date-picker v-model="selectForm.sortTime" show-time placeholder="选择时间" style="width: 100%"/>
                <a-tag color="#2db7f5" style="float: left">
                  <a-icon type="info-circle" />&nbsp;用于排序和倒计时
                </a-tag>
              </a-form-model-item>
            </a-form-model>
          </div>
        </div>
      </div>
      <!-------------选择模版,设置模板-------->
      <div class="steps-content" v-show="show2">
        <div style="display: flex;justify-content: space-between">
          <div>
            <a-button @click="addTable" type="primary">新增</a-button>
          </div>
          <div>
            功能备注作为功能名称显示: &nbsp;<a-switch checked-children="是" un-checked-children="否" default-un-checked @change="remarkOpen" />
          </div>

        </div>
        <div>
          <a-table :columns="columns"  :data-source="InfoTable.funcCu" :pagination="false" :rowKey="(record,index)=>{return index}">
          <span slot="name" slot-scope="text,item,index">
            <span v-if="index<2" :style="{'color': (index<2?'#42b983':'')}" >{{item.name}}</span>
            <a-input v-else v-model="item.name" placeholder="功能名称" style="width: 200px" />
          </span>
            <span slot="pic"  slot-scope="text,item,index">
            <div v-if="index>1" class="selectIcon"  :style="{backgroundImage: item.icon ? 'url(' + item.icon + ')' :'  '}" >
              <input  style="cursor:pointer;width: 40px;height: 40px;opacity: 0;" accept="image/*" type="file"
                      ref="selectIcon" @change="selectIcon(item)"  />
            </div>
           </span>
            <span slot="type" slot-scope="text,item,index">
            <a-select v-if="index>1" v-model="item.code" placeholder="选择类型" style="min-width: 180px">
             <a-select-option v-for="item in typeList" :value="item.code" >{{item.name}}</a-select-option>
            </a-select>
          </span>
            <span slot="open" slot-scope="text,item,index">
            <a-switch v-if="index<2" :default-checked=true  checked-children="是" un-checked-children="否" @change="selectSwitch($event,item)" />
          </span>

          </a-table>
<!--          <a-table :columns="columns" :showHeader="false" :data-source="InfoTable.func_cu" :pagination="false" :rowKey="(record,index)=>{return index}">-->
<!--          <span slot="pic"  slot-scope="item">-->
<!--            <div class="selectIcon"  :style="{backgroundImage: item.icon ? 'url(' + item.icon + ')' :'  '}" >-->
<!--              <input  style="cursor:pointer;width: 40px;height: 40px;opacity: 0;" accept="image/*" type="file"-->
<!--                      ref="selectIcon" @change="selectIcon(item)"  />-->
<!--            </div>-->
<!--           </span>-->
<!--            <span slot="name" slot-scope="item">-->
<!--             <a-input v-model="item.name" placeholder="功能名称" style="width: 180px" />-->
<!--            </span>-->
<!--            <span slot="type" slot-scope="list">-->
<!--            <a-select v-model="list.code" placeholder="选择类型" style="width: 180px" >-->
<!--             <a-select-option v-for="item in typeList" :value="item.code" >{{item.name}}</a-select-option>-->
<!--            </a-select>-->
<!--          </span>-->
<!--          </a-table>-->
        </div>
      </div>
      <div class="steps-content" style="display: flex;justify-content: center" v-show="show3">
        <a-result
            status="success"
            title="设置成功，请进入【会议管理】查看!"
        />
      </div>
      <div class="steps-action">
        <a-button  type="primary" v-show="show1" @click="templateAdd">
          下一步
        </a-button>
        <a-button  type="primary" v-show="show2" @click="next">
          提交
        </a-button>
        <a-button v-show="prevShow"  style="margin-left: 8px" @click="prev">
          上一步
        </a-button>
      </div>
    </a-modal>
    <!--打开下载页面-->
    <a-drawer
        title="下载"
        width="800"
        :visible="downFileShow"
        @close="()=>{this.downFileShow = false;this.sonId = null}"
    >
      <div class="downFile_Template" v-for="(item,index) in templateSon" :key="item.id">
        <div class="Template">
          <span class="Template_title">{{item.name}}</span>
          <div class="Template_pic">
            <img style="width: 80%;height: 231px" :src="item.egImg" alt="">
          </div>
        </div>
        <a-divider style="margin: 5px 0 0 0 ;height: 1px; padding: 0" />
        <div class="Template_btn">
<!--          <div class="btn" @click="addsBtn(item.id)">新增</div>-->
<!--          <a-divider type="vertical" />-->
          <div class="btn" @click="downFileSon(item.psdFile)">下载</div>
          <a-divider type="vertical" />
          <!--          <div class="btn" @click="templateBtn(item.id)">选择</div>-->
<!--          <div class="btn" @click="selectBtn(item.explains)">说明</div>-->
<!--          <a-divider type="vertical" />-->
          <div class="btn" @click="editBtn(item.id)">编辑</div>
          <a-divider v-if="index === 0 && templateSon.length === 1" type="vertical" />
          <a-popconfirm
              v-if="index === 0 && templateSon.length === 1"
              title="确定删除?"
              ok-text="是"
              cancel-text="否"
              @confirm="delTemplates(item.id)"
              @cancel="cancel"
          >
            <div class="btn">删除</div>
          </a-popconfirm>
          <a-divider v-if="index !== 0" type="vertical" />
          <a-popconfirm
              v-if="index !== 0"
              title="确定删除?"
              ok-text="是"
              cancel-text="否"
              @confirm="delTemplates(item.id)"
              @cancel="cancel"
          >
            <div class="btn">删除</div>
          </a-popconfirm>
        </div>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import {update, update_File,updateType} from "@/utils/update";
import {
  delTemplate,
  getFuncs,
  getTemplateColorPic, getTemplateFile,
  getTemplateIcon,
  getTemplate,
  getTemplateTitle, getTemplatrsId, geTypeList, postConvention, postFunc,
  postTemplates, putConvention, getTemplateSon
} from "@/service/medicalConference_api";
import moment from "moment/moment";
import Vue from "vue";


export default {
  name: "homepageTemplate",
  data(){
    return{
      option:[
        {name:'医驰',code:'yichimeeting',value:'https://yichimeeting-mp.yichimeeting.com'},
        {name:'云ICU',code:'yunicu',value:'https://yunicu-mp.yunicu.com'},
        {name:'IE- Learning',code:'ie-learning',value:'https://ielearning-mp.ie-learning.cn'},
        {name:'APnet',code:'aphouse',value:'https://apnet-mp.aphouse.cn'},
      ],
      columns:[
        {
          title: '功能',
          scopedSlots: { customRender: 'name' },
        },
        {
          title: '图标',
          scopedSlots: { customRender: 'pic' },
        },
        // {
        //   title: '功能备注',
        //   scopedSlots: { customRender: 'remark' },
        // },
        {
          title: '类型',
          scopedSlots: { customRender: 'type' },
        },
        {
          title: '是否开启',
          scopedSlots: { customRender: 'open' },
        },
      ],
      current: 0,
      steps: [
        {
          title: '填写会议信息',
          content: 'First-content',
        },
        {
          title: '设置功能',
          content: 'Second-content',
        },
        {
          title: '完成',
          content: 'Last-content',
        },
      ],
      labelCol: { span: 4 },
      wrapperCol: { span: 16 },
      labelCol1: { span: 6 },
      wrapperCol1: { span: 16 },
      addShow:false,
      parentId:0,
      editShow:false,
      templateShow:false,
      pagination:{
        total:0,
        current: 1,
        pageSize:10,
      },
      addForm:{
        egImg:'',
        mainColor:'#45a5e5',
        tempId:undefined,
        createdUserName:'',
        bgImg:'',
        psdFile:'',
        funcCu:[],
        explains:'',
      },
      editForm:{},
      loadImg:false,
      rules: {
        egImg:[{ required: true, message: '请上传示例图', trigger: 'blur' }],
        tempId:[{required: true, message: '请选择模板', trigger: 'blur' }],
        bgImg:[{ required: true, message: '请上传背景图', trigger: 'blur' }],
        psdFile:[{ required: true, message: '请上传源文件',trigger: 'blur' }],
      },
      selectRef:{
        title:[{ required: true,message: '请填写会议名称', trigger: 'blur' }],
        address:[{ required: true,message: '请填写会议地址', trigger: 'blur' }],
        cover:[{ required: true, message: '请上传封面图', trigger: 'blur' }],
        selectTimes:[{ required: true, message: '请选择会议日期', trigger: 'blur'}],
        questionCode:[{ required: true, message: '请选择所属平台', trigger: 'change' }],
        sortTime:[{ required: true, message: '请选择直播时间',trigger: 'change' }],
      },
      explainsVisible:false,
      explainsValue:'',
      templates:[],
      TemplateList:[],
      templateTitle:[],
      templateIcon:[
      ],
      InfoTable2:[],
      templateColorPic:{},
      selectForm:{
      },
      InfoTable: {
        funcCu:[
          {
            name:'轮播广告',
            code:'LBGG',
            icon:'',
            open:1
          },
          {
            name:'字幕文字通知',
            code:'ZMTZ',
            icon:'',
            open:1
          },
        ]
      },
      show1:true,
      show2:false,
      show3:false,
      prevShow:false,
      infoData:[],
      selectOpen:0,
      coverPic:[],
      cover:'',
      typeList:[],
      templateEdit:[],
      editIcons:[],
      downFileShow:false,
      templateSon:[],
      sonId:null,
      templateSonLength:0,
      topSelectVal:undefined,
    }
  },
  created() {
    this.$store.dispatch('setManageHeaderTitle', "首页模板管理")
    const user_info = window.localStorage.getItem("user_info");
    this.addForm.createdUserName =  JSON.parse(user_info).user_name
  },
  mounted() {
    this.getTemplates()
    this.getTemplateTitle()
    // this.getTemplateIcon()
  },
  methods:{
    //获取模板
    async getTemplates(pageNum,name){
      let data = {
        page_no:pageNum,
        name:name,
      }
      const Info = await getTemplate(data)
      this.templates = Info.data
      this.pagination.total = Info.count
    },
    //通过id获取子级模板
    async getSonTemplates(id){
      const Info = await getTemplateSon(id)
      this.templateSon = Info.data
      this.templateSonLength = Info.data.length
    },
    //新增选择模板
    async getTemplateTitle(){
      const Info = await getTemplateTitle()
      // for (let i = 0 ; i < Info.data.rows.length ; i++) {
      //   if (Info.data.rows[i].isOpen === 1){
      //     this.templateTitle.push({
      //       id: Info.data.rows[i].id,
      //       isBanner: Info.data.rows[i].isBanner,
      //       isBgimg: Info.data.rows[i].isBgimg,
      //       isNotice: Info.data.rows[i].isNotice,
      //       isOpen: Info.data.rows[i].isOpen,
      //       name: Info.data.rows[i].name
      //     })
      //   }
      // }

      for (let i = 0 ; i < Info.data.length ; i++) {
        if (Info.data[i].isOpen === 1){
          this.templateTitle.push({
            id: Info.data[i].id,
            isBanner: Info.data[i].isBanner,
            isBgimg: Info.data[i].isBgimg,
            isNotice: Info.data[i].isNotice,
            isOpen: Info.data[i].isOpen,
            name: Info.data[i].name
          })
        }
      }
    },
    async getTemplateIcon(){
      const Info =await getTemplateIcon()
      for (let i = 0; i < Info.data.cus.length; i ++) {
        if (Info.data.cus[i].isOpen === 1){
          this.templateIcon.push({
            name : Info.data.cus[i].name,
            icon : '',
            code : Info.data.cus[i].funcTypeCode,
          })
        }

      }
    },
    //分页跳转
    pageChange(e){
      this.getTemplates(e)
    },
    selectName(value){
      this.topSelectVal = value
      this.getTemplates(1 ,value)
    },
    selectReset(){
      this.topSelectVal = undefined
      this.getTemplates()
    },
    async uploadPic2(type){
      let file = this.$refs[type].files[0]
      if (file !== undefined) {
        if (file.size<2097152){
          const image = await updateType(file,'live')
          if (image.code === 0) {
            this.coverPic.push(
                image.data.url
            )
            Vue.set(this.selectForm,"cover", image.data.url)
            this.$message.success('上传成功')
          } else {
            //  上传失败
            this.$message.error("上传失败，请重试。", image.message)
          }
        }else {
          this.$message.error("图片尺寸请小于2M")
        }
      }
    },
    //上传图片
    async uploadPic(type){
      let file = this.$refs[type].files[0]
      if (file !== undefined) {
        if (file.size<1048576){
          const image = await updateType(file,'live')
          document.getElementById(type).value = null
          if (image.code === 0) {
            switch (type) {
              case'add_egImg':
                this.addForm.egImg = image.data.url
                break;
              case'bgpic':
                this.addForm.bgImg = image.data.url
                break;
              case'bghomePic':
                this.selectForm.bgImg = image.data.url
                break;
              // case'cover':
              //   this.coverPic.push(
              //       image.data.url
              //   )
              //   Vue.set(this.selectForm,"cover", image.data.url)
              //   break;
              case'edit_bgpic':
                this.editForm.bgImg = image.data.url
                break;
              case'edit_egImg':
                this.editForm.egImg = image.data.url
                break;
            }
            this.$message.success('上传成功')
          } else {
            //  上传失败
            this.$message.error("上传失败，请重试。", image.message)
          }
        }else {
          this.$message.error("图片尺寸请小于1M")
        }
      }
    },
    //上传源文件
    async uploadFile(type){
      this.loadImg = true
      let file = this.$refs[type].files[0];
      if (file !== undefined){
        let newVar = await updateType(file,'live')
        document.getElementById(type).value = null
        if(newVar.code === 0){
          switch (type) {
            case'addFile':
              this.addForm.psdFile = newVar.data.url
              break;
            case'editFile':
              this.editForm.psdFile = newVar.data.url
              break;
          }
          this.$message.success('文件上传成功')
        }else {
          this.$message.error('上传文件失败'+ newVar.message)
        }
      }
      this.loadImg = false
    },
    closeAdd(){
      this.$refs.addItem.resetFields();
      this.addShow = false;
      this.parentId = ''
      this.addForm = {
        egImg:'',
        mainColor:'#45a5e5',
        tempId:undefined,
        createdUserName:'',
        bgImg:'',
        psdFile:'',
        funcCu:[],
        explains:'',
      }
    },
    closeEdit(){
      this.$refs.editItem.resetFields();
      this.editShow = false;
      this.templateEdit=[];
      // this.sonId = null;
      this.editForm = {}
    },
    //上传常用功能图标
    // async addIcon(index){
    //   let inputDOM = document.getElementById('add' + index);
    //   let file= inputDOM.files[0];
    //   const pic = file.size / 1024 < 500;
    //   if (!pic){
    //     this.$message.warning('上传图片大小不能超过500k')
    //   }else {
    //     const image =await update(file)
    //     if (image.code === 0){
    //       this.$set( this.templateIcon, index, {
    //         name:this.templateIcon[index].name,
    //         code:this.templateIcon[index].code,
    //         icon:image.data.url
    //       })
    //       this.addForm.func_cu.push({
    //         name:this.templateIcon[index].name,
    //         icon: image.data.url,
    //         code:this.templateIcon[index].code
    //       })
    //       this.$message.success('上传成功')
    //     }
    //     else {
    //       this.$message.warning('上传失败'+ image.message)
    //     }
    //   }
    // },
    //修改常用功能图标
    async editIcon(index){
      let inputDOM = document.getElementById('edit' + index);
      let file= inputDOM.files[0];
      const pic = file.size / 1024 < 500;
      if (!pic){
        this.$message.warning('上传图片大小不能超过500k')
      }else {
        const image =await updateType(file,'live')
        if (image.code === 0){
          this.$set( this.templateEdit, index, {
            name:this.templateEdit[index].name,
            code:this.templateEdit[index].code,
            icon:image.data.url
          })
          this.editIcons.push({
            name:this.templateEdit[index].name,
            icon: image.data.url,
            code:this.templateEdit[index].code
          })
          this.editForm.funcCu = this.editIcons.concat(this.editForm.funcCu)
          let hash = {}
          this.editForm.funcCu = this.editForm.funcCu.reduce((item, next) => {
            if (!hash[next.name]) {
              hash[next.name] = true
              item.push(next)
            }
            return item
          }, [])
          this.$message.success('上传成功')
        }
        else {
          this.$message.warning('上传失败'+ image.message)
        }
      }
    },
    async selectIcon(item){
      let file = this.$refs.selectIcon.files[0]
      if (file !== undefined) {
        // if (file.size<1048576){
          const image = await updateType(file,'live')
          if (image.code === 0) {
            item.icon = image.data.url
            this.$message.success('上传成功')
          } else {
            //  上传失败
            this.$message.error("上传失败，请重试。", image.message)
          }
        // }else {
        //   this.$message.error("图片尺寸请小于1mb")
        // }
      }
    },
    //模板选择
    typeSelect(id,type){
      switch (type) {
        case'add':
          this.addForm.tempId = id
          break;
        case'edit':
          this.editForm.tempId = id
          break;
      }
    },
    //主色值选择
    colorAdd(e){
      this.addForm.mainColor = e.target.value
    },
    colorEdit(e){
      this.editForm.mainColor = e.target.value
    },
    colorSelect(e){
      this.selectForm.mainColor = e.target.value
    },
    //打开新增弹框，获取常用功能
    addBtn(id){
      //新增是父级id为0，新增子级id为父级id
      this.parentId = id
      this.addShow = true
    },
    //打开子级新增弹框，获取常用功能
    addsBtn(id){
      //新增是父级id为0，新增子级id为父级id
      this.parentId = id
      this.addShow = true
    },
    async editBtn(id){
      this.templateId = id
      const edit = await getTemplatrsId(id)
      this.editForm = edit.data
      this.templateEdit = this.editForm.funcCu.concat(this.templateIcon)
      let hash = {}
      this.templateEdit = this.templateEdit.reduce((item, next) => {
        if (!hash[next.name]) {
          hash[next.name] = true
          item.push(next)
        }
        return item
      }, [])
      this.editShow = true
    },
    //确认新增
    async addOk(){
      this.$refs.addItem.validate(async valid =>{
        if (valid){
          let form = this.addForm
          let data = {
            //新增是父级id为0，新增子级id为父级id
            parentId:this.parentId,
            egImg:form.egImg,
            mainColor:form.mainColor,
            tempId:form.tempId,
            createdUserName:form.createdUserName,
            bgImg:form.bgImg,
            psdFile:form.psdFile,
            funcCu:form.funcCu,
            explains:form.explains
            // explains:form.explains.replace(/\r\n/g, '<br/>').replace(/\n/g, '<br/>').replace(/\s/g, ' ')
          }
          const add = await postTemplates(data)
          if (add.code===200){
            this.$message.success('添加成功')

            await this.getTemplates(this.pagination.current)

            this.$refs.addItem.resetFields();
            this.addForm = {}
            this.closeAdd()
          }
        } else {
          this.$message.error("输入有空~")
          return false;
        }
      })
    },
    //修改确认提交
    async editOk(){
      this.$refs.editItem.validate(async valid =>{
        if (valid){
          const form = this.editForm
          const data = {
            egImg : form.egImg,
            tempId : form.tempId,
            bgImg : form.bgImg,
            mainColor : form.mainColor,
            psdFile : form.psdFile,
            funcCu : form.funcCu,
            // explains : form.explains.replace(/\r\n/g, '<br/>').replace(/\n/g, '<br/>').replace(/\s/g, ' ')
            explains : form.explains
          }
          const edit = await putConvention(this.templateId,data)
          if (edit.code===200){
            this.$message.success('修改成功')
            await this.getSonTemplates(this.sonId)
            await this.getTemplates(this.pagination.current)
            this.$refs.editItem.resetFields();
            this.editShow = false;
            this.templateEdit=[];
            this.editForm = {}
          }
        } else {
          this.$message.error("输入有空~")
          return false;
        }
      })
    },


    selectSwitch(checked,item){
      if (checked === false){
        item.open = 0
      }else {
        item.open = 1
      }
    },
    //模板选择会议按钮
    async templateBtn(id){
      this.show3= false
      this.show1= true
      this.prevShow = false
      this.current = 0
      this.templateId = id
      const Info =await getTemplateColorPic(id)
      this.selectForm = Info.data
      // this.InfoTable2.push({
      //       name : '轮播广告',
      //       open :  this.selectForm.is_banner
      //     },{
      //       name : '字幕文字通知',
      //       open :  this.selectForm.is_notice
      //     }
      // )
      this.selectForm.isBgimg = Info.data.isBgimg === 1?'背景图':''
      this.selectForm.isBanner = Info.data.isBanner === 1?'/轮播广告':''
      this.selectForm.isNotice = Info.data.isNotice === 1?'/字幕文字通知':''
      this.selectForm.Info = this.selectForm.name + '('+ this.selectForm.isBgimg  + this.selectForm.isBanner +  this.selectForm.isNotice  + ')'
      this.templateShow = true
    },
    selectRule(){
      this.$refs.selectRef1.validate( async valid=>{
        if (valid){

        }else {
          return false;
        }
      })
    },
    async templateAdd(){
      this.selectRule()
      this.$refs.selectRef.validate(async valid =>{
        if (valid){
          const form = this.selectForm
          const data = {
            coverPics: this.coverPic,
            mainColor : form.mainColor,
            homeCoverImage : form.bgImg,
            tempUrl : this.optionValue+form.url,
            title : form.title,
            address  :form.address,
            beginDate:form.selectTimes[0].format('YYYY-MM-DD'),
            endDate: form.selectTimes[1].format('YYYY-MM-DD'),
            domainCode: form.domainCode,
            sortTime:form.sortTime.format('YYYY-MM-DD HH:ss')
          }
          const add = await postConvention(data)
          if (add.code===0){
            // const Infos = await getFuncs(this.templateId)
            // this.InfoTable = Infos.data[0]
            this.meetingId = add.data
            const Info = await geTypeList()
            this.typeList = Info.data.types
            this.$message.success('添加成功')
            this.current++;
            this.show1 = false
            this.show2 =true
            this.prevShow = true
          }
        } else {
          this.$message.error("输入有空~")
          return false;
        }
      })
    },
    remarkOpen(checked){
      if (checked === false){
        this.selectOpen = 0
      }else {
        this.selectOpen = 1
      }
    },
    addTable(){
      let ifTrue = true
      this.InfoTable.funcCu.forEach(item => {
        if (item.name === '') {
          this.$message.warning('请完善选项')
          ifTrue = false
        }})
      if (ifTrue) {
        this.InfoTable.funcCu.push({
          name :'',
          icon:'',
          code:''
        })}
    },
    async next(){
      this.current++;
        if ( this.InfoTable.funcCu[0].open === 1){
          this.infoData.push({
            intro:'轮播广告',
            funcCode:'LBGG',
            icon:"",
            isOpen: 1,
            conventionId : this.meetingId
          })
        }
        if ( this.InfoTable.funcCu[1].open === 1){
          this.infoData.push({
            intro:'字幕文字通知',
            funcCode:'ZMTZ',
            icon:"",
            isOpen: 1,
            conventionId : this.meetingId
          })
        }
      // if (this.selectOpen === 0){
        for (let i = 2; i < this.InfoTable.funcCu.length; i++) {
          this.infoData.push({
            funcCode : this.InfoTable.funcCu[i].code,
            icon : this.InfoTable.funcCu[i].icon,
            intro : this.InfoTable.funcCu[i].name,
            isOpen: 1,
            conventionId : this.meetingId
          })
        }
      // }else {
      //   for (let i = 0; i < this.InfoTable.func_cu.length; i++) {
      //     this.infoData.push({
      //       func_code : this.InfoTable.func_cu[i].code,
      //       icon : this.InfoTable.func_cu[i].icon,
      //       title: this.InfoTable.func_cu[i].name,
      //       intro : this.InfoTable.func_cu[i].name,
      //       is_open: 1,
      //       convention_id :this.meetingId
      //     })
      //   }
      // }
      const Info = await postFunc(0,this.infoData)
      if (Info.code === 0){
        this.$message.success('设置成功')
        this.show2 =false
        this.show3 = true
        this.prevShow = false
        this.InfoTable= []
        this.InfoTable2 = []
        await this.getTemplates(this.pagination.current)
      }else {
        this.InfoTable= []
        this.InfoTable2 = []
      }
    },
    close(){
      this.show1 = true
      this.show2 = false
      this.show3 = false
      this.InfoTable= []
      this.InfoTable2 = []
      this.selectForm = {}
    },
    prev() {
      this.current--;
      this.show1 = true
      this.show2 = false
    },
    //短信验证码签名code
    selectMode(value){
      this.optionValue = value
    },
    //选择会议日期
    selectTime(date, dateString){
      this.selectForm.beginDate = dateString[0]
      this.selectForm.endDate = dateString[1]
    },

    async downFile(id){
      this.sonId = id
      await this.getSonTemplates(id)
      this.downFileShow = true
    },
    async downFileSon(url){
      window.open(url)
    },
    //删除模板
    async delTemplates(id){
      const Info = await delTemplate(id)
      if (Info.code === 200){
        this.$message.success('删除成功')

        await this.getSonTemplates(this.sonId)
        await this.getTemplates(this.pagination.current)

        if(this.templateSonLength === 0){
          this.downFileShow = false;
        }
      }else {
        this.$message.warn('删除失败'+ Info.message)
      }
    },
    cancel(){
      this.$message.warning('取消删除');
    },
    selectBtn(explains){
      if(explains){
        this.explainsValue = explains
      }else {
        this.explainsValue = '暂无说明'
      }
      this.explainsVisible = true
    },
    explainsOk(){
      this.explainsVisible = false
      this.explainsValue = ''
    },
    explainsCancel(){
      this.explainsVisible = false
      this.explainsValue = ''
    },

  },
}
</script>

<style lang="scss" scoped>

textarea {
  white-space: pre-wrap; //保留所有空格
}
.steps-content {
  margin-top: 16px;
  min-height: 400px;
  text-align: center;
  padding-top: 10px;
}

.steps-action {
  margin-top: 24px;
  display: flex;
  justify-content: center;
}
.PIC_list{
  width:20%;
}
.uploadImg{
  border: #DDDDDD 2px dashed;
  width: 100px;
  height: 100px;
  margin: 0 auto;
  background-image: url("../../../assets/add.png");
  background-size:100% 100%;
  background-repeat:no-repeat;
  cursor: pointer;
}
.addIcon{
  border: #DDDDDD 2px dashed;
  width: 50px;
  height:50px;
  margin: 0 auto;
  background-image: url("../../../assets/add.png");
  background-size:100% 100%;
  background-repeat:no-repeat;
  cursor: pointer;
}
.selectIcon{
  border: #DDDDDD 2px dashed;
  width: 40px;
  height:40px;
  background-image: url("../../../assets/add.png");
  background-size:100% 100%;
  background-repeat:no-repeat;
  cursor: pointer;
}
.upload_image{
  border: #DDDDDD 2px dashed;
  width: 100px;
  height: 100px;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
.upload_File{
  width: 150px;
  border: 1px #DDDDDD solid;
  border-radius:4px;
  height: 32px;
  background-image: url("../../../assets/add_imgfile.png");
  background-size:70% 70%;
  background-position:center center;
  background-repeat:no-repeat;
  cursor: pointer;
}
.homepageTemplate{
  margin: -15px -15px;
  background-color:rgba(240, 242, 245, 1);
  .Info_search{
    height: 60px;
    padding: 15px 40px;
    background-color: white;
  }
  .Info_list{
    margin:  auto;
    width: 100%;
    height: 100%;
    min-height:542px;
    .addBtn{
      z-index:1;
      margin: 1%;
      border-radius: 3px;
      border: #DDDDDD 2px dashed;
      cursor: pointer;
      width: 22%;
      height: 270px;
      float: left;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .Info_list_Template{
      border-radius: 3px;
      border:#DDDDDD  1px solid;
      background-color: white;
      width: 22%;
      float: left;
      height: 270px;
      margin: 1%;
      .Template{
        position: relative;
        .Template_title_top{
          position: absolute;
          right: 0px;
          background-color: rgba(2, 167, 240, 1);
          min-width: 51px;
          padding: 3px;
          height: 29px;
          color: white;
          font-size: 13px;
          text-align: center;
          line-height: 24px;
        }
        .Template_title{
          float: right;
          //background-color: rgba(2, 167, 240, 1);
          min-width: 51px;
          height: 29px;
          color: white;
          font-size: 13px;
          text-align: center;
          line-height: 29px;
        }
        .Template_pic{
          display: flex;
          justify-content: center;
          margin: 0 auto 0 50px;
        }
      }
      .Template_btn{
        cursor: pointer;
        height: 31px;
        display: flex;
        padding: 0 !important;
        background-color:rgba(247, 249, 250, 1);
        justify-content: space-between;
        ::v-deep .ant-divider, .ant-divider-vertical{
          height: auto;
        }
        .btn{
          width:100%;
          display: flex;
          font-size: 11px;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}
.downFile_Template{
  border-radius: 3px;
  border:#DDDDDD  1px solid;
  background-color: white;
  width: 31%;
  float: left;
  height: 270px;
  margin: 1%;
  .Template{
    .Template_title{
      float: right;
      background-color: rgba(2, 167, 240, 1);
      width: 51px;
      height: 29px;
      color: white;
      font-size: 13px;
      text-align: center;
      line-height: 29px;
    }
    .Template_pic{
      display: flex;
      justify-content: center;
      margin: 0 auto 0 50px;
    }
  }
  .Template_btn{
    cursor: pointer;
    height: 31px;
    display: flex;
    padding: 0 !important;
    background-color:rgba(247, 249, 250, 1);
    justify-content: space-between;
    ::v-deep .ant-divider, .ant-divider-vertical{
      height: auto;
    }
    .btn{
      width:100%;
      display: flex;
      font-size: 11px;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
