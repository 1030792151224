/**
 * name:fmx
 * data:2021年12月16日
 */
import { get, put, post, del, getDrugExel } from "./base";
//文献指南
export function getDocument(is_show, title, page_no, page_size, is_top, time) {
  return get("/api/admin/document", {
    is_show,
    title,
    page_no,
    page_size,
    is_top,
    time,
  });
}
// export function getDocumentTitle(title){
//     return get('/api/admin/document',{"title":title})
// }
//修改文献列表，获取详情
export function getDocumentContent(id) {
  return get("/api/admin/document/" + id);
}
//删除文献列表
export function delList(ids) {
  return del("/api/admin/document", { ids: ids });
}
//{"id":id,"is_show":'is_show'}
export function putDocument(data) {
  return put("/api/admin/document", data);
}
export function putDocumentContent(data) {
  return put("/api/admin/document", data);
}
//{"title":title,"describe":describe,"show_date":show_date,"image":image,"url":url,"remake":remake}
export function postDocument(data) {
  return post("/api/admin/document", data);
}
//添加图片
export function postDocumentImg(File) {
  return post("/api/admin/common/update", File, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}


//添加图片---不同类型
export function postTypeImg(File,type) {
  return post("/v3/upload/back/file/"+type, File, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}


//添加文件
export function postDocumentFile(File) {
  return post("/api/admin/common/updateFile", File, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}
//登陆界面，成功传数据 json   {headers: {'Content-Type':'application/json'}}
export function postAdminData(name, data) {
  return post("/api/admin/auth/login_info/" + name, data, {
    headers: { "Content-Type": "application/json" },
  });
}
// 获取制定者列表
export function getDocumentMaker(data) {
  return get("/api/document/maker", data);
}
// 获取疾病框架
export function getDocumentDisease(data) {
  return get("/api/document/disease", data);
}
// 文献指南 --  标记已读记录
export function getReadRecord(startTime, endTime, pageNo, pageSize) {
  return get(
    `/v3/document/readRecord?startTime=${startTime}&endTime=${endTime}&pageNo=${pageNo}&pageSize=${pageSize}`
  );
}
// 文献指南 -- 导出标记已读记录
export function getReadExcel(data) {
  return getDrugExel("/v3/documentExportList/read/export", data);
}
// 文献指南 --  浏览记录
export function getContentRecord(startTime, endTime, curPage, pageSize, type) {
  return get(
    `/v3/userAction/content?startTime=${startTime}&endTime=${endTime}&curPage=${curPage}&pageSize=${pageSize}&type=${type}`
  );
}
// 文献指南 -- 导出文献指南浏览记录
export function getContentExcel(data) {
  return getDrugExel("/v3/userAction/content/export", data);
}

/**
 * 文献指南2.0
 * name:邓换媚
 * data:2024年4月28日
 */

//获取疾病
export function getDisease(name, curPage, pageSize) {
  return get(
    `/api/document/disease?name=${name}&curPage=${curPage}&pageSize=${pageSize}`
  );
}

// 添加疾病
export function postDisease(data) {
  return post("/api/document/disease", data);
}
// 修改疾病
export function putDisease(data) {
  return put("/api/document/disease", data);
}
// 删除疾病
export function delDisease(id) {
  return del(`/api/document/disease/${id}`);
}

// 获取制定者
export function getMaker(name, curPage, pageSize) {
  return get(
    `/api/document/maker?name=${name}&curPage=${curPage}&pageSize=${pageSize}`
  );
}
// 三处制定者
export function delMaker(id) {
  return del(`/api/document/maker/${id}`);
}
// 修改制定者
export function putMaker(data) {
  return put("/api/document/maker", data);
}
// 添加
export function postMaker(data) {
  return post("/api/document/maker", data);
}
// 注销会员管理
export function getMemberCancel(data) {
  return get("/v3/yun/member/cancel", data);
}
// 专家专栏管理 -- 个人主页访问记录
export function getHomePageVisitedRecords(data) {
  return get("/v3/homePage/homePageVisitedRecords", data);
}
// 专家专栏 -- 导出
export function getExportHomePage(data) {
  return getDrugExel("/v3/homePage/exportHomePage", data);
}
// 小程序首页内容管理
export function getminaRecommendList(data) {
  return get("/v3/recommend/mina/list", data);
}
// 小程序上移下移
export function putminaRecommendList(type,id) {
  return put(`/v3/recommend/mina/list/${type}/${id}`);
}
// 上传图片新
export function postImageNew(type, file) {
  return post(`/v3/upload/back/file/${type}`, file, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}
