import { render, staticRenderFns } from "./addNotesRecord.vue?vue&type=template&id=3af4d961&scoped=true"
import script from "./addNotesRecord.vue?vue&type=script&lang=js"
export * from "./addNotesRecord.vue?vue&type=script&lang=js"
import style0 from "./addNotesRecord.vue?vue&type=style&index=0&id=3af4d961&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3af4d961",
  null
  
)

export default component.exports