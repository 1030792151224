import { render, staticRenderFns } from "./Winners.vue?vue&type=template&id=732f7b18&scoped=true"
import script from "./Winners.vue?vue&type=script&lang=js"
export * from "./Winners.vue?vue&type=script&lang=js"
import style0 from "./Winners.vue?vue&type=style&index=0&id=732f7b18&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "732f7b18",
  null
  
)

export default component.exports