<template>
<div class="document">
  <a-spin :spinning="spinning">
    <div class="seachTitle">
      年份: &nbsp;<a-date-picker
        style="width: 10%;margin-right: 15px"
        mode="year"
        :disabled-date="disabledDate"
        placeholder="请选择"
        format='YYYY'
        v-model="yearQuarter"
        :open='yearShowOne'
        @openChange="openChangeOne"
        @panelChange="panelChangeOne"/>
      标题:&nbsp;  <a-input
        v-model="valueTitle"
        placeholder="请输入"
        @pressEnter="enterBtn"
        style="width: 210px;margin-right: 40px"/>
      状态: &nbsp;<a-radio-group default-value="1" style="margin-right: 40px"  @change="selectState">
      <a-radio-button value="1">
        已发布
      </a-radio-button>
      <a-radio-button value="2">
        已置顶
      </a-radio-button>
      <a-radio-button value="3">
        未发布
      </a-radio-button>
    </a-radio-group>
      <a-button @click="onSearch" type="primary">查询</a-button>
      <a-button style="margin-left: 10px" @click="resetBtn">重置</a-button>
    </div>
    <div style="padding: 15px 15px;position: relative;background-color: #fff;">
      <div>
          <!--  添加按钮-->
          <div class="document-btn">
            <a-button type="primary" @click="showModal">新增</a-button>
            <a-popconfirm style="margin-left: 15px" placement="top" ok-text="是" cancel-text="否" @confirm="deleteList">
              <template slot="title">
                <p>是否确认删除？</p>
              </template>
              <a-button>删除</a-button>
            </a-popconfirm>
          </div>
          <!--  文献列表-->
          <!--:columns标题 :data-source数据 :pagination只显示十行 :rowKey添加key record 为每一条数据, index 索引-->
          <div class="document-content">
            <a-table
                :scroll="{ x: 1500}"
                size="middle"
                :columns="columns"
                :data-source="documentList"
                :rowKey='record=>record.id'
                :pagination="false"
                :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
            >
              <!--        序号-->
              <template slot="index" slot-scope="text,row,index">
                {{index + ((pagination.current - 1) * pagination.pageSize) + 1}}
              </template>
              <span slot="title_link" slot-scope="text, record">
                <a-tag v-if="record.is_top === 1" color="#F5222D">
                  置顶
                </a-tag>
                  <a-tooltip placement="topLeft">
                  <template slot="title">
                    {{ text }}
                  </template>
                  <a :href="record.url" target="_blank">{{text}}</a>
                </a-tooltip>
              </span>
              <span slot="describeHandel" slot-scope="text,item,record">
                <!-- 悬浮框 -->
                <a-tooltip placement="topLeft">
                  <template slot="title">
                    {{ text }}
                  </template>
                  <span  class="multi-ellipsis">{{text }}</span>
                </a-tooltip>
                <!-- <a  >{{text }}</a> -->
              </span>

              <!--      按钮的显示与隐藏-->
              <span slot="is_show" slot-scope="text,record,scope" style="display: flex;justify-content: space-around;">
              <a-popconfirm
                  title="确定执行操作？"
                  ok-text="确定"
                  cancel-text="取消"
                  @confirm="dian_btn(record.id,record.is_show === 1?0:1 )"
              >
                <a :disabled="record.recommendState && record.is_show !== 1">{{record.is_show === 1?'上架':'下架'}}</a>
              </a-popconfirm>
            <a-popconfirm
                cancel-text="否"
                ok-text="是"
                title="是否置顶?"
                @confirm="topBtn(record.id,record.is_top === 1?0:1)"
            >
              <a v-show="is_topBtn">{{record.is_top === 1?'取消置顶':'置顶'}}</a>
          </a-popconfirm>
                <!--        修改按钮-->
              <a type="primary"  @click="reviseXiu(record.id,record)">修改</a>


              <a @click="promotions(record)">推广</a>
              </span>
            </a-table>
            <div style="margin-top: 30px;padding-right: 15px;padding-left: 20px">
              <span style="font-size: 17px;color: #929292">共 {{pagination.total}} 条记录 第 {{ pagination.current }} / {{ Math.ceil(pagination.total / pagination.pageSize) }} 页</span>
              <a-pagination
                  :page-size-options="pageSizeOptions"
                  v-model="pagination.current"
                  :total="pagination.total"
                  show-size-changer
                  :page-size="pagination.pageSize"
                  @change="selectPage"
                  @showSizeChange="selectPage"
                  show-quick-jumper
                  style="float: right;margin-bottom: 40px"
              >
              </a-pagination>
            </div>
          </div>
      </div>

  <!--  ======================================对话框====================================-->
  <!--  新增对话框-->
    <a-drawer :visible="visible" title="新增"
              @close="resetForm"
              width="600"
    class="addVisible">
      <!--  新增的表单界面  -->
      <a-form-model
          ref="ruleForm"
          :model="AddForm"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
      >
        <a-form-model-item ref="name" label="标题" prop="title">
          <a-input placeholder="请输入" v-model="AddForm.title"/>
        </a-form-model-item>
        <a-form-model-item label="制定者" prop="describe">
          <a-select
            mode="multiple"
            show-search
            option-filter-prop="children"
            v-model="AddForm.describe"
            placeholder="请选择"
            @popupScroll="popupScroll_describeCode"
            @search="select_describe"
          >
          <!-- @search="select_describe" -->
            <a-select-option
              v-for="item in describeList"
              :value="item.name"
              :key="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="疾病" prop="diseaseList">
          <a-select
            mode="multiple"
            show-search
            option-filter-prop="children"
            v-model="AddForm.diseaseList"
            placeholder="请选择"
          >
            <a-select-option
              v-for="item in describeSelectList"
              :value="item.name"
              :key="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="专家" prop="expertList">
          <a-select
            mode="multiple"
            show-search
            option-filter-prop="children"
            v-model="AddForm.expertList"
            placeholder="请选择"
            @popupScroll="popupScroll_questionCode"
            @search="select_hospital"
          >
          <!-- @search="select_hospital"   @search="select_hospital"-->
            <a-select-option
              v-for="item in ExpertSelectList"
              :value="item.id"
              :key="item.id"
              :label="item.name"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="发布日期" prop="publishTime">
          <div style="display:flex;align-items: center;">
            <span style="margin-right:8px">
              <a-tooltip placement="top">
                <template slot="title">
                  <span>发布日期为该条记录新增的时间，判断是否为新</span>
                </template>
                <a-icon type="exclamation-circle" />
              </a-tooltip>
            </span>
            <a-date-picker
                placeholder="请选择"
                style="width: 100%;"
                v-model="AddForm.publishTime"
            />
          </div>
        </a-form-model-item>
        <a-form-model-item label="上线日期" prop="show_date">
          <div style="display:flex;align-items: center;">
            <span style="margin-right:8px">
              <a-tooltip placement="top">
                <template slot="title">
                  <span>上线日期为该条记录上线的时间</span>
                </template>
                <a-icon type="exclamation-circle" />
              </a-tooltip>
            </span>
            <a-date-picker
                placeholder="请选择"
                style="width: 100%;"
                v-model="AddForm.show_date"
            />
          </div>
        </a-form-model-item>
        <a-form-model-item label="上传图片" prop="image">
          <div style="display: flex;">
            <div class="uploadImg">
              <input type="file" @change="addImage" ref="inputer" class="imageAdd" accept="image/*"/>
            </div>
            <div class="upload_image">
              <img style="width: 185px;height: 103px" v-if="AddForm.image" :src="AddForm.image">
            </div>
          </div>
          <div style="float:left;width: 350px;font-size: 12px">注：建议尺寸500*281px，宽度不能超过500，大小不超过1M</div>
        </a-form-model-item>
        <a-form-model-item label="上传文件" prop="url" class="formMargin">
              <input
                  style="z-index:11;cursor: pointer;width:120px;height: 40px;opacity: 0;position: absolute;top: 0;"
                  type="file"
                  @change="addFile"
                  ref="inputFile"
                  class="fileAdd"
                  accept=".pdf"
              />
          <div class="uploadVideo" >
            <a-icon type="upload" /> 上传文件
          </div>
          <div style="width: 150px;font-size: 12px">请上传pdf格式的文件</div>
        </a-form-model-item>
        <a-form-model-item>
          <div class="fileCard">
            <div>{{AddForm.url}}</div>
          </div>
        </a-form-model-item>
        <a-form-model-item label="关键字" prop="keyword">
          <a-textarea
            v-model="AddForm.keyword"
            placeholder="请输入"
            style="min-height: 100px; font-size: 16px"
          />
        </a-form-model-item>
        <a-form-model-item label="备注" prop="remark">
          <a-input placeholder="请输入" v-model="AddForm.remark"></a-input>
        </a-form-model-item>
      </a-form-model>
      <div
          :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button :style="{ marginRight: '8px' }" @click="resetForm">
          取消
        </a-button>
        <a-button type="primary" @click="addSubmit">
          提交
        </a-button>
      </div>
    </a-drawer>

  <!--  修改对话框-->
    <a-drawer
        title="修改"
        :visible="visibleAdd"
        @close="handleCancel"
        width="600"
        class="modFrom"
    >
      <a-form-model
          ref="ruleFormXiu"
          :model="ticketInfoform"
          :rules="rules"
          :label-col="labelColXiu"
          :wrapper-col="wrapperColXiu"
      >
        <a-form-model-item ref="name" label="标题" prop="title">
          <a-input v-model="ticketInfoform.title"/>
        </a-form-model-item>
        <a-form-model-item label="制定者" prop="describe">
          <a-select
            mode="multiple"
            show-search
            option-filter-prop="children"
            v-model="ticketInfoform.describe"
            placeholder="请选择"
            @popupScroll="popupScroll_describeCode"
            @search="select_describe"
          >
          <!-- @search="select_describe" -->
            <a-select-option
              v-for="item in describeList"
              :value="item.name"
              :key="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="疾病" prop="diseaseList">
          <a-select
            mode="multiple"
            show-search
            option-filter-prop="children"
            v-model="ticketInfoform.diseaseList"
            placeholder="请选择"
          >
            <a-select-option
              v-for="item in describeSelectList"
              :value="item.name"
              :key="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="专家" prop="expertList">
          <a-select
            mode="multiple"
            show-search
            option-filter-prop="children"
            v-model="ticketInfoform.expertList"
            placeholder="请选择"
            @popupScroll="popupScroll_questionCode"
            @search="select_hospital"
          >
          <!-- :key="item.id" -->
            <a-select-option
              v-for="item in ExpertSelectList"
              :value="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <!-- <a-form-model-item label="描述" prop="describe">
          <a-input v-model="ticketInfoform.describe" type="textarea" />
        </a-form-model-item> -->
        <a-form-model-item label="发布日期" prop="publishTime">
          <a-date-picker
              placeholder="请选择"
              style="width: 100%;"
              v-model="ticketInfoform.publishTime"
          />
        </a-form-model-item>
        <a-form-model-item label="上线日期" prop="show_date">
          <a-date-picker
              placeholder="请选择"
              style="width: 100%;"
              v-model="ticketInfoform.show_date"
          />
        </a-form-model-item>
        <a-form-model-item label="上传图片" prop="image">
          <div style="display:flex">
            <div class="uploadImg">
              <input type="file" @change="xiuImage" ref="xiuInput" class="imageMod" accept="image/*"/>
            </div>
            <div class="upload_image">
              <img style="width: 185px;height: 103px" :src="ticketInfoform.image">
            </div>
          </div>
          <div style="float:left;width: 350px;font-size: 12px">注：建议尺寸500*281px，宽度不能超过500，大小不超过1M</div>
        </a-form-model-item>
        <a-form-model-item label="上传文件" prop="url">
          <input
              style="z-index:11;cursor: pointer;width:120px;height: 40px;opacity: 0;position: absolute;top: 0;"
              type="file"
              @change="xiuFile"
              ref="xiuInputer"
              class="fileMod"
              accept=".pdf"
          />
          <div class="uploadVideo" >
            <a-icon type="upload" /> 上传文件
          </div>
          <div style="width: 150px;font-size: 12px">请上传pdf格式的文件</div>
        </a-form-model-item>
        <a-form-model-item>
          <div class="fileCard">
            <div>{{ticketInfoform.url}}</div>
          </div>
        </a-form-model-item>
        <a-form-model-item label="关键字" prop="keyword">
          <a-textarea
            v-model="ticketInfoform.keyword"
            placeholder="请输入"
            style="min-height: 100px; font-size: 16px"
          />
        </a-form-model-item>
        <a-form-model-item  label="备注">
          <a-input  placeholder="请输入" v-model="ticketInfoform.remark"/>
        </a-form-model-item>
      </a-form-model>
      <div
          :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button :style="{ marginRight: '8px' }" @click="resetFormXiu">
          取消
        </a-button>
        <a-button type="primary" @click="XiuSubmit">
          提交
        </a-button>
      </div>

    </a-drawer>

  <!--   推广 -->
      <a-drawer
          title="推广"
          :width="500"
          :visible="promotionsVisible"
          :body-style="{ paddingBottom: '80px' }"
          @close="promotionsClose"
      >
        <a-spin :spinning="promotionSpin">
          <div style="margin:20px">
            <a-table :pagination="false" :row-key="record => record.id" :columns="uniAppColumns" :data-source="uniAppData" bordered>
              <span slot="is_open" slot-scope="text, record">
                 <a-switch v-model="record.is_open" checked-children="是" un-checked-children="否" @click="minaRecommend(record)" default-checked />
               </span>
            </a-table>
           </div>
          <div style="margin:20px">
            <a-table :pagination="false" :columns="appColumns" :data-source="appData" :row-key="record => record.code" bordered>
          <span slot="is_open" slot-scope="text, record">
            <a-switch v-if="record.title == '社区首页'" v-model="record.is_open" checked-children="是" un-checked-children="否" @click="communityListSwitch(record.is_open,record)" default-checked />
            <a-switch v-else v-model="record.is_open" checked-children="是" un-checked-children="否" @click="AppListSwitch(record.is_open,record)" default-checked />
          </span>
            </a-table>
          </div>
        </a-spin>
        <div
            :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
        >
          <a-button :style="{ marginRight: '8px' }" @click="promotionsClose">
            关闭
          </a-button>
        </div>
      </a-drawer>
<!--  &lt;!&ndash;    详情对话框&ndash;&gt;-->
<!--    <a-modal-->
<!--        :visible="row_content"-->
<!--        @cancel="content_close"-->
<!--        class="modFrom"-->
<!--    >-->
<!--      <a-descriptions title="详情">-->
<!--        <a-descriptions-item label="标题" :span="3">-->
<!--          {{ticketInfoform.title}}-->
<!--        </a-descriptions-item>-->
<!--        <a-descriptions-item label="描述" :span="3">-->
<!--          {{ticketInfoform.describe}}-->
<!--        </a-descriptions-item>-->
<!--        <a-descriptions-item label="显示日期" :span="3">-->
<!--          {{ticketInfoform.show_date}}-->
<!--        </a-descriptions-item>-->
<!--        <a-descriptions-item label="上传图片" :span="3">-->
<!--          <img style="width: 200px;height: auto" :src="ticketInfoform.image"/>-->
<!--        </a-descriptions-item>-->
<!--        <a-descriptions-item label="上传文件" :span="3" >-->
<!--          {{ticketInfoform.url}}-->
<!--        </a-descriptions-item>-->
<!--        <a-descriptions-item label="备注" :span="3">-->
<!--          {{ticketInfoform.remark}}-->
<!--        </a-descriptions-item>-->
<!--      </a-descriptions>-->
<!--      &lt;!&ndash;    去除a-model自带的取消和确认&ndash;&gt;-->
<!--      <div slot="footer"></div>-->

<!--    </a-modal>-->
    </div>
  </a-spin>
</div>
</template>

<script>
import {
  delList,
  getDocument,
  getDocumentContent, getDocumentTitle,
  postDocument,
  putDocument,
  putDocumentContent,
  getDocumentMaker,
  getDocumentDisease
} from "../../service/document_api";
import {info} from "sass";
import {update,update_File} from "../../utils/update";
import moment from "moment";
import {
  delAppPromotion,
  getColumnVodsList, getAppPromotion, getcommunityPromotion,
  postAppPromotion,
  postcommunityPromotion
} from "@/service/MedicalConference_y";
import {postminaRecommend,getminaRecommend,delminaRecommend} from "@/service/Recruiting";
import {getExpertList} from "@/service/zoomMeet_api";

const columns = [
  {
    title: '序号',
    // fixed: 'left',
    align: 'center',
    width: 60,
    scopedSlots: {customRender: 'index'}
  },
  {
    title: '标题',
    dataIndex: 'title',
    align:'left',
    width: '25%',
    scopedSlots: { customRender: 'title_link' },
    ellipsis: true,
  },
  {
    title: '制定者',
    dataIndex: 'describe',
    width: '20%',
    align:'left',
    key: 'describe',
    scopedSlots: { customRender: 'describeHandel' },
    // customRender(arr){
    //   return arr.map(item=>{  
    //       return item;   //使其返回数组中的一项
    //   }).join("、");   //因为数据中user_name可能是两个人所以用逗号分隔开
    // }
  },


  // {
  //   title: '订阅单位类型',
  //   align:'center',  
  //   dataIndex: 'unitType' ,
  //   key: 'unitType',
  //   customRender(arr){
  //     return arr.map(item=>{  
  //         return item;   //使其返回数组中的一项
  //     }).join("、");   //因为数据中user_name可能是两个人所以用逗号分隔开
  //   }
  //  //  scopedSlots: { customRender: 'unitType' },
  // },


  {
    title: '发布日期',
    dataIndex: 'publishTime',
    key: 'publishTime',
    width: '15%',
    align:'center',
  },
  {
    title: '上线日期',
    dataIndex: 'show_date',
    key: 'show_date',
    width: '15%',
    align:'center',
  },
  {
    title: '更新时间',
    dataIndex: 'alter_time',
    key: 'alter_time',
    width: '15%',
    align:'center',
  },
  {
    title: '操作',
    dataIndex: 'is_show',
    key: 'is_show',
    width: 200,
    align:'center',
    fixed: 'right',
    scopedSlots: { customRender: 'is_show' },
  },
];
// //点击修改按钮上传图片，创造全局的getBase64函数
// async function getBase64(originFileObj) {
//
// }
export default {
  name: "Document",
  data(){
    return{
      uniAppColumns:[
        {
          title: '小程序',
          key: 'title',
          dataIndex: 'title',
        },
        {
          title: '状态',
          dataIndex: 'is_open',
          key: 'is_open',
          scopedSlots: { customRender: 'is_open' },
        },
      ],
      uniAppData:[
        {
          id:1,
          title:'推荐页（首页）',
          is_open:false
        }
      ],
      DescribeName:'',
      DescribePageSize:10,
      DescribeCurPage:10,
      expertPageNum:1,
      expertName:'',
      describeSelectList:[],
      describeList:[],
      ExpertSelectList:[],
      pageSizeOptions: ['10', '20', '30', '40', '50'],
      pagination:{
        total:0,
        current: 1,
        pageSize:10,
      },
      is_topBtn:true,
      isTop:0,
      count:0,
      user_id:'',
      showBtn:0,
      selectedRowKeys:[],
      spinning:false,
      valueTitle:'',
      //标题
      titleList:[],
      //获取文献列表详情
      documentContent:[],
      documentList:[],
      columns,
      show:true,
      //默认不显示添加表单
      visible: false,
      //默认不显示修改表单
      visibleAdd:false,
      //默认不显示
      // row_content:false,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      labelColXiu: { span: 4 },
      wrapperColXiu: { span: 14 },
      //添加
      AddForm: {
        title:'',
        describe:[],
        image:'',
        url:'',
        remark: '',
        show_date: '',
        diseaseList:[],
        expertList:[],
        publishTime:'',
        keyword:'',
      },
      rules: {
        title: [{required: true, message: '请填写标题', trigger: 'blur'},],
        show_date: [{required: true, message: '请选择时间', trigger: 'change'}],
        publishTime: [{required: true, message: '请选择时间', trigger: 'change'}],
        describe: [{required: true, message: '请选择制定者', trigger: 'blur'}],
        remark:[{ trigger: 'blur'}],
        image:[{required: true, message: '请上传文件', trigger: 'blur'}],
        url:[{required: true, message: '请上传文件', trigger: 'blur'}]
      },
      ticketInfoform: {
        //标题
        // name: '',
        title:'',
        //显示日期
        show_date: '',
        publishTime:'',
        image:'',
        url:'',
        is_show:'',
        //描述
        describe:[],
        //备注
        remark:'',
        diseaseList:[],
        expertList:[],
        keyword:'',

        // is_show:'',
        // title:'',
        // describe:[],
        // image:'',
        // url:'',
        // remark: '',
        // show_date: '',
        // diseaseList:[],
        // expertList:[],
        // publishTime:'',
        // keyword:'',
      },
      previewImage: '',

      promotionsVisible:false,
      appColumns:[
        {
          title: 'APP',
          key: 'title',
          dataIndex: 'title',
        },
        {
          title: '状态',
          dataIndex: 'is_open',
          key: 'is_open',
          scopedSlots: { customRender: 'is_open' },
        },
      ],
      appData:[],
      columnListApp:[],
      promotionId:null,
      // subContentType:null,
      promotionSpin:false,
      communityColumns:[
        {
          title: '社区',
          key: 'title',
          dataIndex: 'title',
        },
        {
          title: '状态',
          dataIndex: 'is_open',
          key: 'is_open',
          scopedSlots: { customRender: 'is_open' },
        },
      ],
      communityData:[
        {
          id:1,
          title:'社区首页',
          is_open:false,
          code:null,
        }
      ],
      // communityCode:'',
      yearQuarter: null,
      yearShowOne: false,
    }
  },
  created() {
    this.$store.dispatch('setManageHeaderTitle',"文献指南管理")
    //获取接口列表
    this.getDocument()
  },
  mounted() {

  },
  methods: {
    // async minaRecommend(){
    //   const data = {
    //     contentId:this.promotionId,
    //     contentType:'DOCUMENT',
    //     // subContentType:this.subContentType,
    //   }
    //   const response = await postminaRecommend(data)
    //   if(response.code === 0){
    //     this.$message.success("操作成功！")
    //   }else {
    //     this.$message.warning(response.message)
    //   }
    // },
    async minaRecommend(record){
      if(record.is_open === true){
        const data = {
          contentId:this.promotionId,
          contentType:'DOCUMENT',
          // subContentType:this.subContentType,
        }
        const response = await postminaRecommend(data)
        if(response.code === 0){
          this.$message.success("操作成功！")
        }else {
          this.$message.warning(response.message)
        }
      }else{
        const data = {
          contentId:this.promotionId,
          contentType:'DOCUMENT',
          // subContentType:this.subContentType,
        }
        const response = await delminaRecommend(data)
        if(response.code === 0){
          this.$message.success("操作成功！")
        }else {
          this.$message.warning(response.message)
        }
      }
    },
    //点添加按钮显示表单
    showModal() {
      this.getDescribeList()
      this.getDiseaseList()
      this.getLogData()
      this.spinning = true
      this.visible = true;
      this.spinning = false
      if(!this.AddForm.image || this.AddForm.image === '')this.AddForm.image = 'https://image.yunicu.com/other/b23e0bbf-30cd-4085-a6b9-cbb56a560e65.jpg'
    },
    //添加的取消按钮
    resetForm() {
      //关闭表单
      this.visible = false
      // 关闭表单时重置专家框的数据
      this.expertName = ''
      this.expertPageNum = 1
      this.ExpertSelectList = []
      this.DescribeCurPage = 1
      this.DescribeName = ''
      this.describeSelectList = []
      this.describeList = []
      this.$refs.ruleForm.resetFields()
      // this.$refs.add.clearValidate();
      //  this.addForm = {}
      // this.addShow = false;
      // this.hospitalNameList = []; //清空医院表单
      // this.departmentNameList = []; //清空科室表单
    },
    // 修改中点击❌关闭弹框
    handleCancel(e) {
      this.visibleAdd = false;
      // 关闭表单时重置专家框的数据
      this.expertName = ''
      this.expertPageNum = 1
      this.ExpertSelectList = []
      this.DescribeCurPage = 1
      this.DescribeName = ''
      this.describeSelectList = []
      this.describeList = []
      // this.$refs.ruleFormXiu.resetFields()
    },
    // 专家狂滚动条监听
    popupScroll_questionCode(e) {
      const { target } = e;
      const scrollHeight = target.scrollHeight - target.scrollTop; //滑动总高度
      const clientHeight = target.clientHeight; //滑动最低高度
      if (scrollHeight < clientHeight + 2) {
        this.expertName = ''
        this.expertPageNum++;
        this.getLogData();
      }
    },
    // 专家框的搜索
    select_hospital(value) {
      // this.hospitalNameList = [];
      // this.departmentNameList = [];
      this.ExpertSelectList = []
      this.expertName = value;
      this.expertPageNum = ''
      // let values = this.addForm.provinceCity;
      // const data = {
      //   province: values[0],
      //   city: values[1],
      //   name: value,
      //   pagenum: 1,
      //   pagesize: 10,
      // };
      this.getLogData();
    },
    // 制定者框
    async getDescribeList() {
      // this.departmentNameList = [];
      // this.$set(this.addForm, "hospitalDepartmentId", undefined);
      const data = {
        name:this.DescribeName,
        pageSize:this.DescribePageSize,
        curPage:this.DescribeCurPage,
      }
      const response = await getDocumentMaker(data);
      if (response.code === 0) {
        let list = response.data;
        for (let i = 0; i < list.length; i++) {
          this.describeList.push({
            id: list[i].id,
            name: list[i].name,
          });
        }
        // console.log("看列表",this.describeList)
      } else {
        this.$message.error("获取错误，请联系管理员~" + response.message);
      }
    },
    // 制定者的滚动条  
    popupScroll_describeCode(e) {
      const { target } = e;
      const scrollHeight = target.scrollHeight - target.scrollTop; //滑动总高度
      const clientHeight = target.clientHeight; //滑动最低高度
      if (scrollHeight < clientHeight + 2) {
        this.DescribeCurPage++;
        this.getDescribeList();
      }
    },
    // 制定者的搜索
    select_describe(value) {
      // this.hospitalNameList = [];
      // this.departmentNameList = [];
      this.describeList = []
      this.DescribeName = value;
      this.DescribeCurPage = ''
      this.getDescribeList();
    },
    // 疾病框 (数量少，没写滚动加载和搜索加载)
    async getDiseaseList() {
      // this.departmentNameList = [];
      // this.$set(this.addForm, "hospitalDepartmentId", undefined);
      const data = { 
        name:"",
        pageSize:9999,
        curPage:1,
      }
      const response = await getDocumentDisease(data);
      if (response.code === 0) {
        let list = response.data;
        for (let i = 0; i < list.length; i++) {
          this.describeSelectList.push({
            id: list[i].id,
            name: list[i].name,
          });
        }
        // console.log("看疾病列表",this.describeSelectList)
      } else {
        this.$message.error("获取错误，请联系管理员~" + response.message);
      }
    },
    // 弹出日历和关闭日历的回调
    openChangeOne(status) {
      if (status){
        this.yearShowOne =  true
      }
    },
    // 得到年份选择器的值
    panelChangeOne(value) {
      this.yearQuarter = value;
      this.yearShowOne = false;
    },
    disabledDate(current) { // 限制日期选择
      return current && moment(current).format('YYYY') > moment().format('YYYY')
    },
    //获取接口列表
    async getDocument(titleName){
      // if (page === 0) {
      //   return
      // }
      this.spinning = true
      //获取接口
      const is_show = this.showBtn
      let is_top = this.isTop
      if (is_show === 0) {
        is_top = undefined
      }
      let time
      if (!this.yearQuarter) {
        time = ''
      }else {
        time = moment(this.yearQuarter).format('YYYY')
      }
      const documentLists = await getDocument(is_show,this.valueTitle,this.pagination.current,this.pagination.pageSize,is_top, time)
      //判断接口的code是否为0
      if(documentLists.code === 0){
        //将获取到的列表赋值
        this.documentList = documentLists.data.rows
        this.pagination.total = documentLists.data.count
      }else {
        //出错误的话报错
        this.$message.warning('出错，请联系管理员'+ documentLists.message);
      }
      this.spinning = false
    },
    async selectPage(page,pageSize){
      this.pagination.pageSize = pageSize
      this.pagination.current = page
      await this.getDocument('')
    },
    selectState(e){
      let activeKey = e.target.value
      const titleName = this.valueTitle
      switch (activeKey){
        //已发布   搜索---显示且未置顶
        case '1':
          this.showBtn = 0
          this.isTop = 0
          this.is_topBtn = true
          this.getDocument(titleName)
              break;
        //已置顶  搜索---已置顶
        case '2':
          this.showBtn = ''
          this.isTop = 1
          this.is_topBtn = true
          this.getDocument(titleName)
          break;
        //未发布  搜索---未发布的，就是隐藏的
        case '3':
          this.showBtn = 1
            this.isTop = ''
          //  不显示置顶，取消置顶按钮
            this.is_topBtn = false
          this.getDocument(titleName)
          break;
        default:
          break;
      }
    },
    //添加上传图片
    async addImage(){
      let inputDOM = this.$refs.inputer.files[0];
      let reader = new FileReader() // 读取文件对象
      reader.readAsDataURL(inputDOM) //读取文件并将文件以URL的形式保存在result属性中 base64格式
      reader.onload = ((e)=> {
        let result = e.target.result // 获取base64格式图片
        const image = new Image() // 创建图片对象
        image.onerror = (e) => {
          this.$message.warning('图片加载失败，请联系管理员')
        }
        image.src = result // 将生成的base64格式作为图片地址
        image.onload = (async () => {
          if (inputDOM.size > 1048576) {
            this.$message.warning('图片大小不能超过1M')
            return
          }
          if (image.width > 500) {
            this.$message.warning('图片宽度不能超过500')
            return
          }
          let newVar = await update(inputDOM);
          if(newVar.code === 0){
            this.AddForm.image = newVar.data.url
            this.$message.success('图片上传成功')
          }else {
            this.$message.warning("上传失败！"+ newVar.$message)
          }

        })
      })
    },
    //添加上传文件
    async addFile(){
      let file = this.$refs.inputFile.files[0];
      if (file !== undefined){
        let newVar = await update_File(file);
        this.AddForm.url = newVar.data.url
          if(newVar.code === 0){
            this.AddForm.url = newVar.data.url
            this.$message.success('文件上传成功')
          }else {
            this.$message.error('上传文件失败'+ newVar.message)
          }
      }
    },
    //添加的确认按钮
    async addSubmit() {
      //判断表单中的内容不为空
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
            const data = {
              describe: this.AddForm.describe.join("、"),
              // describe: "制定者1,制定者2,制定者3", {...this.AddForm.describe} 
              image: this.AddForm.image,//封面
              remark: this.AddForm.remark,//备注
              show_date: moment(this.AddForm.show_date).format("YYYY-MM-DD"),//展示时间
              title: this.AddForm.title,//标题
              url: this.AddForm.url,
              publishTime:moment(this.AddForm.publishTime).format("YYYY-MM-DD"),//发布时间
              diseaseList: this.AddForm.diseaseList.join("、"),//疾病列表
              expertList: this.AddForm.expertList,//专家列表
              keyword:this.AddForm.keyword,//
              // is_show:1,
            }
            // console.log("数组转化",data)
            //调取post接口
            const documentPostLists = await postDocument(data)
            if (documentPostLists.code === 0) {
              //刷新界面
              await this.getDocument();
              //清空界面
              this.$refs.ruleForm.resetFields();
              //关闭表单
              this.visible = false
              this.$message.success('添加成功');
              // 重置选择框
              this.expertName = ''
              this.expertPageNum = 1
              this.ExpertSelectList = []
              this.DescribeCurPage = 1
              this.DescribeName = ''
              this.describeSelectList = []
              this.describeList = []
            } else {
              this.$message.warning('出错，请联系管理员' + documentPostLists.message);
            }
          }  else {
          this.$message.error("表单有空，请填写或选择！")
          return false;
        }
      });
    },
    //修改上传图片
    async xiuImage(file){
      const formXiu = this.ticketInfoform
      let inputDOM = this.$refs.xiuInput.files[0];
      let reader = new FileReader() // 读取文件对象
      reader.readAsDataURL(inputDOM) //读取文件并将文件以URL的形式保存在result属性中 base64格式
      reader.onload = ((e)=> {
        let result = e.target.result // 获取base64格式图片
        const image = new Image() // 创建图片对象
        image.onerror = (e) => {
          this.$message.warning('图片加载失败，请联系管理员')
        }
        image.src = result // 将生成的base64格式作为图片地址
        image.onload = (async () => {
          if (inputDOM.size > 1048576) {
            this.$message.warning('图片大小不能超过1M')
            return
          }
          if (image.width > 500) {
            this.$message.warning('图片宽度不能超过500')
            return
          }
          let newVar = await update(inputDOM);
          if(newVar.code === 0){
            // this.form.image = newVar.data.url
            formXiu.image = newVar.data.url
            this.$message.success('图片上传成功')
          }else {
            console.log("上传失败",newVar.message)
          }
        })
      })

    },
    //添加上传文件，限制pdf格式
    async xiuFile(){
      const formXiu = this.ticketInfoform
      let file = this.$refs.xiuInputer.files[0];
      if (file!==undefined){
        let newVar = await update_File(file);
        if(newVar.code === 0){
          formXiu.url = newVar.data.url
          this.$message.success('文件上传成功')
        }else {
          console.log("上传失败",newVar.message)
        }
      }
    },

    //点击修改回显
    async reviseXiu(id,record){
      this.getDescribeList()
      this.getDiseaseList()
      this.getLogData()
      this.spinning = true
      //1.点击修改按钮获取当前行的数据
      const response = await getDocumentContent(id)
      if(response.code === 0){
        // console.log("回显数据",response.data) 
        // var describeToARR = response.data.describe.split('、') // a.split(',')
        // console.log("hhh",hhh)
        let ticketInfoform = response.data
        ticketInfoform.id = id
        ticketInfoform.show_date = moment(ticketInfoform.show_date).format("YYYY-MM-DD")
        if(response.data.describe && response.data.describe.includes("、")){
          ticketInfoform.describe = response.data.describe.split('、') // a.split(',')  
        }else{
          ticketInfoform.describe = response.data.describe // 但是一个字符串也能回显
        }
        if(response.data.diseaseList && response.data.diseaseList.includes("、")){
          ticketInfoform.diseaseList = response.data.diseaseList.split('、') // a.split(',')  
        }else if(response.data.diseaseList == ""){
          // ticketInfoform.diseaseList = response.data.diseaseList // a.split(',')
          ticketInfoform.diseaseList = [] // a.split(',')
        }
        // 专家框的回显问题
        var list = []
        for(var i = 0;i<response.data.experts.length;i++){
          list.push(response.data.experts[i].id)
        }
        ticketInfoform.expertList = list
        // 选框需要添加还未加载的option，需要根据名字搜索选项
        var ExpertName = []
        for(var i = 0;i<response.data.experts.length;i++){
          ExpertName.push(response.data.experts[i].name)
        }
        // console.log("专家名字",ExpertName)
        for(var i = 0;i<ExpertName.length;i++){
          var indexOf = ExpertName[i].indexOf("【")
          this.expertName = ExpertName[i].substring(0, indexOf);
          // console.log("需要传的数据",this.expertName)
          this.getLogData()
        }
        this.ticketInfoform = ticketInfoform
        this.visibleAdd = true
      }else {
        this.$message.error('获取接口失败'+ response.message);
      }
      this.spinning = false
    },
    //专家框
    async getLogData(){
      const data={
        pageNum:this.expertPageNum,
        // memberId:'',
        // pageSize:"99999",
        name:this.expertName,
      }
      const response = await  getExpertList(data)
      if (response.code === 0) {
        let list = response.data.rows;
        for (let i = 0; i < list.length; i++) {
          this.ExpertSelectList.push({
            id: list[i].id,
            name: list[i].name,
          });
        }
         console.log("看列表",this.ExpertSelectList)
      } else {
        this.$message.error("获取错误，请联系管理员~" + response.message);
      }
    },
    //修改的确认按钮
    XiuSubmit() {
      this.$refs.ruleFormXiu.validate(async valids => {
        if (valids) {
          console.log("数据form",this.ticketInfoform.diseaseList) 
          // 处理疾病列表 如果不为空，就是说再转换，如果为空，就直接传空""?
          if(!this.ticketInfoform.diseaseList){
            this.ticketInfoform.diseaseList = ""
          }
          const data = {
              id:this.ticketInfoform.id,
              alter_time:this.ticketInfoform.alter_time,
              describe:typeof this.ticketInfoform.describe == "string"?this.ticketInfoform.describe:this.ticketInfoform.describe.join("、"),  //有拦截所以不用写存在
              image: this.ticketInfoform.image,//封面
              remark: this.ticketInfoform.remark,//备注
              show_date: moment(this.ticketInfoform.show_date).format("YYYY-MM-DD"),//展示时间
              title: this.ticketInfoform.title,//标题
              url: this.ticketInfoform.url,
              publishTime:moment(this.ticketInfoform.publishTime).format("YYYY-MM-DD"),//发布时间
              // diseaseList:this.ticketInfoform.diseaseList? this.ticketInfoform.diseaseList.join("、"):'',//疾病列表
              diseaseList: typeof this.ticketInfoform.diseaseList == "string"?this.ticketInfoform.diseaseList:this.ticketInfoform.diseaseList.join("、"),
              expertList: this.ticketInfoform.expertList? this.ticketInfoform.expertList:[],//专家列表
              keyword:this.ticketInfoform.keyword//
            }
            // console.log("数据form",this.ticketInfoform,typeof this.ticketInfoform.describe,) 
            // 调取put接口
            // const form = this.ticketInfoform;
            // form.show_date = moment(form.show_date).format("YYYY-MM-DD")
            const documentPutLists = await putDocumentContent(data)
            if (documentPutLists.code === 0) {
              //刷新界面
              await this.getDocument();
              this.visibleAdd = false
              this.$message.success('修改成功');
              this.expertName = ''
              this.expertPageNum = 1
              this.ExpertSelectList = []
              this.DescribeCurPage = 1
              this.DescribeName = ''
              this.describeSelectList = []
              this.describeList = []
            } else {
              this.$message.warning('出错，请联系管理员' + documentPutLists.message);
            }
          } else {
          this.$message.error("表单有空，请填写或选择！")
          return false;
        }
      });

    },
    //修改的取消按钮
    resetFormXiu() {
      this.visibleAdd = false;
    },
    //修改点击按钮，显示与隐藏
    async dian_btn(id,is_show){
      this.spinning = true
      const data = {
        id:id,
        is_show:parseInt(is_show)
      }
      //调取put接口,执行修改
      const documentPutLists = await putDocument(data)
      //判断code执行成功
      if(documentPutLists.code === 0){
        //重新获取列表，刷新界面
        await this.getDocument('');
        this.$message.success('操作成功')
      }else {
        this.$message.warning('出错，请联系管理员'+ documentPutLists.message);
      }
      this.spinning = false
    },
    //制定与下架
    async topBtn(id,is_top){
      const data = {
        id:id,
        is_top:parseInt(is_top)
      }
      //调取put接口,执行修改
      const documentPutLists = await putDocument(data)
      //判断code执行成功
      if(documentPutLists.code === 0){
        //重新获取列表，刷新界面
        await this.getDocument('');
        this.$message.success('操作成功')
      }else {
        this.$message.warning('出错，请联系管理员'+ documentPutLists.message);
      }
    },
    //删除列表
    async deleteList(){
      const userIds = this.user_id
      let list = [];
      userIds.forEach(item => {
        this.documentList.some(item2 => {
          if (item2.id === item && item2.recommendState) {
            list.push(item2)
          }
          return item2.id === item
        })
      })
      if (list.length > 0) {
        this.$message.warning('包含已推广数据，无法删除')
        return
      }
      if(userIds !== '' && userIds !== null && userIds !== undefined){
        const response = await delList(userIds)
        if(response.code === 0){
          this.$message.success("删除成功")
          //刷新列表
          await this.getDocument()
          //清空user_id
          this.user_id = ''
        }else {
          this.$message.error('出错请联系管理员！' + response.message)
        }
        //清空
        this.selectedRowKeys = []
      }else {
        this.$message.warning("请选择需要删除的列表！")
      }
    },
    //获取id
    onSelectChange(selectedRowKeys){
      // //选择的列表的id
      this.user_id = selectedRowKeys
      // //显示是否被选中
      this.selectedRowKeys = selectedRowKeys;
    },
    //添加中点击❌关闭弹框
    handleOk(e) {
      this.visible = false;
    },
    // 点击搜索按钮搜索相应内容
    async onSearch() {
      // this.showBtn = 0
      //获取输入框中的内容，并赋值
      const titleName = this.valueTitle
      await this.getDocument(titleName)
      // const titles = await getDocumentTitle(titleName)
      // this.titleList = titles.data.rows
      // this.documentList = this.titleList
    },
    //输入框按下回车
    enterBtn(){
      this.onSearch()
    },
    //重置按钮
    resetBtn(){
      this.valueTitle = ''
      this.pagination = {
        total:0,
        current: 1,
        pageSize:10,
      }
      this.yearQuarter = undefined
      //恢复初始状态，默认不显示隐藏的
      // this.showBtn = 0
      this.getDocument()
    },
    async promotions(list) {
      this.promotionId = list.id
      // this.subContentType = list.type
      //获取栏目
      this.getColumnList(list.id)
      //获取社区
      // this.getcommunityList(list.id)
      this.promotionsVisible = true

      // 小程序推广回显  
      const data = {
        contentId:this.promotionId,
        contentType:'DOCUMENT',
        // subContentType:this.subContentType,
      }
      const response = await getminaRecommend(data)
      if (response.code === 0) {
        if(response.data[0] && response.data[0].code === "YUN_ICU"){
           this.uniAppData[0].is_open = true
        }else{
          this.uniAppData[0].is_open = false
        }
      } else {
        this.$message.warning(response.message)
      }
    },
    async getcommunityList(id) {
      this.promotionSpin = true
      const response = await getcommunityPromotion(id,'DOCUMENT')
      if (response.code === 0) {
        if(response.data.length && response.data[0].isOpen){
          // this.communityData[0].is_open = true
          // this.communityData[0].code = response.data[0].code

          this.appData[0].is_open = true
          this.appData[0].code = response.data[0].code
        }else {
          this.appData[0].is_open = false
        }
      } else {
        this.$message.warning(response.message)
      }
      this.promotionSpin = false
    },
    async getColumnList(id) {
      this.promotionSpin = true
      this.appData = []
      const response = await getColumnVodsList()
      if (response.code === 0) {
        this.appData = response.data
        this.appData.unshift({
          id:1,
          title:'社区首页',
          is_open:false
        })
        await this.getAppList(id)
      } else {
        this.$message.warning(response.message)
      }
      this.promotionSpin = false
    },
    async getAppList(id) {
      const response = await getAppPromotion(id)
      if (response.code === 0) {
        this.columnListApp = response.data
        //对比
        let app = this.appData
        let columnList = this.columnListApp
        app.forEach((a,index) =>{
          a.is_open = columnList.some(b =>{
            return b.code === a.code
          })
          this.$set(app,index,a)
        })
        await this.getcommunityList(id)  //加载app中社区首页的数据
      } else {
        this.$message.warning(response.message)
      }
    },
    promotionsClose(){
      this.promotionsVisible = false
      this.appData = []
      this.promotionId = null
      this.getDocument()
      // this.subContentType = null
    },
    async AppListSwitch(isOpen, row) {
      //推广
      if(isOpen){
        let data = [
          {
            contentId: this.promotionId,//内容id
            contentType: "COMMUNITY",//一级内容
            columnCode: row.code,//栏目
            // subContentType: this.subContentType//子级内容
            subContentType: 'DOCUMENT'//子级内容
          }
        ]
        await this.postAppSwitch(data,row)
      }else { //关闭推广
        await this.delAppSwitch(this.promotionId,row.code,'app',row)
      }
    },
    async postAppSwitch(data,row) {
      const response = await postAppPromotion(data)
      if (response.code === 0) {
        //更新数据
        this.appData.forEach((a,index) => {
          if (a.code === row.code) {
            this.$set(this.appData, index, a)
            return
          }
        })
        this.$message.success("推广成功~")
      } else {
        this.$message.warning(response.message)
      }
    },
    async delAppSwitch(id,code,type,row) {
      let data = {
        code:code,
        type:type
      }
      const response = await delAppPromotion(id,data)
      if (response.code === 0) {
        //更新数据
        this.appData.forEach((a,index) => {
          if (a.code === row.code) {
            this.$set(this.appData, index, a)
            return
          }
        })
        this.$message.success("关闭成功~")
      } else {
        this.$message.warning(response.message)
      }
    },
    async communityListSwitch(isOpen, row) {
      //推广
      if (isOpen) {
        let data = {
          // type:this.subContentType,//内容类型
          type:'DOCUMENT',//内容类型
          contentId:this.promotionId,//内容id
          isOpen:1, //是否启用：0：否 1：是
          isTop: 0
        }
        await this.postcommunitySwitch(data)
        //  重新获取社区列表
        this.getcommunityList(this.promotionId)
      } else { //关闭推广
        await this.delAppSwitch(this.promotionId, row.code, 'community', row)
      }
    },
    async postcommunitySwitch(data) {
      const response = await postcommunityPromotion(data)
      if (response.code === 0) {
        // //更新数据
        // this.appData.forEach((a,index) => {
        //   if (a.code === row.code) {
        //     this.$set(this.appData, index, a)
        //     return
        //   }
        // })
        this.$message.success("推广成功~")
      } else {
        this.$message.warning(response.message)
      }
    },

    //点击标题查看详情
    // list_content(row){
    //   this.row_content = true
    //   this.spinning = true
    //   //1.点击标题获取整行的信息 row
    //   //2.将信息赋值给ticketInfoform
    //   row.show_date = moment(row.show_date).format("YYYY-MM-DD")
    //   this.ticketInfoform = row
    //   //3.显示弹出框
    //   this.row_content = true
    //   this.spinning = false
    // },
    // //关闭详情对话框
    // content_close(){
    //   this.row_content = false
    // }
  },


}
</script>

<style lang="scss" scoped>
.editable-row-operations a {
  margin-right: 8px;
}
.document{
  margin: -15px -15px;
  .document-content{
    //出现滑动效果
    overflow: auto;
    width: 100%;
    border: 1px solid #f6f5f5;
    margin-top: 10px;
  }
  .document-btn{
    .aBtn{
      width: 70px;
      height: 35px;
      color:#ffffff;
      background-color: rgb(31,147,229);
    }
  }
}
.addVisible{
  //  上传图片的样式
  .add-image{
    margin-top: 8px;
    width: 80px;
    height: 25px;
    background-image: url("../../assets/addImage.png") ;
    background-repeat: no-repeat ;
    background-size: 80px 25px;
    float: left;
    cursor: pointer;
  }
  .imageCard{
    width: 278px;
    margin-left: 28%;
  }
  .add-file {
    margin-top: 8px;
    width: 80px;
    height: 25px;
    background-image: url("../../assets/addFile.png") ;
    background-repeat: no-repeat ;
    background-size: 80px 25px;
    float: left;
    .fileAdd {
      width: 80px;
      height: 25px;
      opacity: 0;
    }
  }

}
//修改界面
.modFrom{
  .mod-image{
    margin-top: 8px;
    width: 80px;
    height: 25px;
    background-image: url("../../assets/addImage.png") ;
    background-repeat: no-repeat ;
    background-size: 80px 25px;
    float: left;
    cursor: pointer;
  }
  .modImage{
    width: 278px;
    margin-left: 28%;
  }
  .mod-file{
    margin-top: 8px;
    width: 80px;
    height: 25px;
    background-image: url("../../assets/addFile.png") ;
    background-repeat: no-repeat ;
    background-size: 80px 25px;
    float: left;
    .fileMod{
      width: 80px;
      height: 25px;
      opacity: 0;
    }
  }
  .modFile{
    width: 278px;
    margin-left: 28%;
  }
}
.seachTitle{
  width: 100%;
  height: 70px;
  border-bottom: 10px solid #f5f5f5;
  padding: 15px 15px;
}
.tab_btn{
  display: flex;
  background-color: #f5f5f5;
}
.issue{
  width: 80px;
  height: 50px;
  line-height:50px;
  text-align: center;
  border: 1px solid #f5f5f5;
  background-color: #fff;
  cursor: pointer;
}
.issue:hover{
  color: #1890ff;
}
.issue:active{
  color: #1890ff;
}
.stick{
  width: 80px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  border: 1px solid #f5f5f5;
  background-color: #fff;
  margin-left: 10px;
  cursor: pointer;
}
.stick:hover{
  color: #1890ff;
}
.stick:active{
  color: #1890ff;
}
.unIssue{
  width: 80px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  border: 1px solid #f5f5f5;
  background-color: #fff;
  margin-left: 10px;
  cursor: pointer;
}
.unIssue:hover{
  color: #1890ff;
}
.unIssue:active{
  color: #1890ff;
}
.upload_image{
  border: #DDDDDD 2px dashed;
  width: 190px;
  height: 108px;
  float: left;
  margin-left: 10px;
}
.uploadImg{
  border: #DDDDDD 2px dashed;
  width: 120px;
  height: 120px;
  background-image: url("../../assets/add.png");
  background-size:100% 100%;
  background-repeat:no-repeat;
  cursor: pointer;
  float: left;
}
.imageAdd{
  width: 120px;
  height: 120px;
  opacity: 0;
}
.uploadVideo{
  border: #DDDDDD 1px solid;
  border-radius: 5px;
  width: 120px;
  text-align: center;
  height: 40px;
  cursor: pointer;
  position: relative;
}
.imageMod{
  width: 120px;
  height: 120px;
  opacity: 0;
}
.fileCard{
  width: 278px;
  margin-left: 28%;
  margin-top: 0;
}
.formMargin {
  margin-bottom: -24px;
}
.multi-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; 
  -webkit-box-orient: vertical;
  cursor: pointer;
}
</style>
